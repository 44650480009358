/* eslint-disable import/prefer-default-export */
export const features = {
  PAYMENT: 1,
  BUILDER: 2,
  WAIT_LIST: 3,
  CALENDAR_SYNC: 6,
  CUSTOM_NOTIFICATIONS: 7,
  SOCIAL_MEDIA_SHARING: 8,
  CALL_CENTRE: 11,
  ENABLE_SALESFORCE: 12,
  VENDOR_SWITCHER: 13,
  G_SUITE: 18,
  LOBBY: 20,
  UTC_EVENT_TIME: 25,
  EXCHANGE_CALENDAR_SYNC: 34,
  SYNC_REPORTING: 47,
  INVITATIONS: 56,
  RESERVE_WITH_GOOGLE: 57,
  APPOINTMENT_MEETING_METHODS: 58,
  ALL_CLIENTS_VISIBLE: 60,
  JANUARY: 62,
  SHORTCUTS: 63,
  FEBRUARY: 64,
  SSO_SETTINGS_TOOL: 67,
  MOBILE_SPECIALIST: 68,
  BUSINESS_HOURS_OVERRIDE: 71,
  ROOM_BOOKINGS: 76,
  VIDEO_CALL_INTEGRATIONS: 80,
  MICROSOFT_ENTRA_ID: 86,
  RESOURCE_CATEGORIES: 92,
  MCV_CUSTOM_CONTACT: 94,
  MULTIPLE_STAFF_APPOINTMENTS: 96,
  HOURS_WEEK_VIEW: 98,
  RESTRICT_RESOURCE_ACCESS: 100,
  GUIDE_NAV_ITEM: 101,
  DISPLAY_LOCATION_IDENTIFIERS: 103,
  GOOGLE_TAG_MANAGER: 104,
  CONTACT_CENTRE_LOCATION_SEARCH: 109,
  CONTACT_CENTRE_SETTINGS_PAGE: 116,
  STAFF_DISPLAY_SETTINGS: 117,
  COCONUT_CONNECT_APPOINTMENTS: 119,
  NEW_ADVANCE_BOOKING_POLICY: 122,
  NEW_EVENT_WINDOW: 123,
  INTEGRATION_DASHBOARD: 124,
  CLIENT_GOOGLE_LOGIN: 126,
  COCONUT_CONNECT_CONTACT_CENTRE: 127,
  COCONUT_CONNECT_LOBBY: 128,
  UPLOAD_QUESTION_TYPE: 129,
  CLIENT_GOOGLE_RECAPTCHA: 131,
  MULTIPLE_ORGANIZATIONS: 132,
  ADMIN_SHARING_BOOKING_SHORTCUTS: 135,
  APPOINTMENT_BOOKING_CHANNEL: 136,
  REMOTE_IN_BRANCH: 137,
  ESCALATION_NOTIFICATIONS: 308,
  STAFF_SPOKEN_LANGUAGES: 310,
  CURBSIDE_PICKUP: 312,
  BRANCH_LOCATOR: 313,
  MULTI_SERVICE_WALK_INS: 314,
  CO_BROWSING: 400,
  CONNECT_ID_VERIFICATION: 403,
  CONNECT_INSTANT_MEETINGS: 405,
  E_SIGNATURES: 408,
  LIVE_AGENT_CHAT: 410,
  ADVISOR_CHAT: 418,
  CONNECT_STAFF_NOTIFICATIONS: 420,
  HIDE_REPORTS_AND_DATA_MANAGEMENT: 500,
  MICROSOFT_DYNAMICS_INTEGRATION: 501,
  WORKFLOW_MANAGEMENT: 503,
  EXTERNAL_DATA_DESTINATION: 504,
  SORT_STAFF_BY_AVAILABILITY: 600,
  EMAIL_INSTRUCTIONS_ALT_POSITION_SETTING: 604,
  SALESFORCE: 700,
  TEALIUM: 702,
  ADOBE: 703,
  ALLOW_CUSTOM_SORT_ORDER: 704,
  BANNO_INTEGRATION: 705,
};

export const DEFAULT_SHORTCUT_SETTINGS = {
  invite_only_resources: false,
  booking_window_start: null,
  booking_window_end: null,
  preferred_staff: false,
  preferred_location: false,
  sort: null,
};

export const DIGITAL_BANKING_PROVIDERS = {
  BANNO: 3,
};

export const SELECTION_STORAGE_KEY = 'selection-state';
export const LANGUAGE_STORAGE_KEY = 'language';
export const USER_LOCATION_STORAGE_KEY = 'user-location';
// Rugby, North Dakota, yee haw (centre of North America)
// same location used on client view
export const DEFAULT_MAP_LOCATION = {
  lat: 48.365691,
  lng: -99.99659,
};

export const FORMDATA_STORAGE_KEY = 'callback-selection-state';

export const SUPPORTED_LOCALES = {
  en: 'en',
  fr: 'fr',
  es: 'es',
  ko: 'ko',
  pl: 'pl',
  pt: 'pt',
  ru: 'ru',
  zh: 'zh',
};

export const LOCALE_NAMES = {
  en: 'English',
  fr: 'Français',
  es: 'Español',
  ko: '한국어',
  pl: 'Polski',
  pt: 'portugues (brasil)',
  ru: 'Русский',
  zh: '简体中文',
};

export const CAPITALIZATION = {
  SENTENCE: {
    en: true,
    fr: false,
    es: false,
    ko: false,
    pl: true,
    pt: true,
    ru: true,
    zh: false,
  },
};

export const ONE_TIME_USER_EVENTS = {
  PENDO_CONNECT_GUIDE: '9vsM4vIzq233y790KnFnsrpuPB0',
};

export const MEETING_METHODS = {
  AT_BUSINESS: 1,
  PHONE_CALL: 2,
  VIDEO_CALL: 3,
  OFF_SITE: 4,
};

export const RECOMMENDED_IFRAME_HEIGHT = '580';

export const visibilities = {
  PUBLIC: 0,
  PRIVATE: 1,
  INVITE_ONLY: 2,
};

export const DIGITAL_BANKING_STRINGS = {
  BANNO: 'banno',
};

// This value is to format the max search range of 6 months into minutes
// 1440 (minutes in a day) * 365 (days in a year) / 2 (half a year)
export const MAX_FIND_AVAILABILITY_SEARCH_RANGE_MINUTES = 262800;
