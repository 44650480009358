export default {
    "AdditionalUsers.four_plus": "{first}, {second} 및 {number} 더 보기",
    "AdditionalUsers.plus_more": "+ {number} 더 보기",
    "AdditionalUsers.three": "{first}, {second} 및 {third}",
    "AdditionalUsers.two": "{first} 및 {second}",
    "AppointmentCancelled.email_message": "취소를 확인하는 이메일을 보내드렸습니다.",
    "AppointmentCancelled.this_title": "이 약속은 취소됨",
    "AppointmentCancelled.your_title": "약속이 취소됨",
    "AppointmentDetails.appointment_details": "약속 세부 정보",
    "AppointmentDetails.appointment_duration": "{start} - {end}({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start}({timezone})",
    "AppointmentDetails.attendee": "참석자",
    "AppointmentDetails.attendees": "참석자",
    "AppointmentDetails.confirmation_number": "확인 번호",
    "AppointmentDetails.date_time": "날짜 및 시간",
    "AppointmentDetails.join_by_phone": "전화로 참여: <a>{number}<\/a>",
    "AppointmentDetails.join_call": "통화 참여",
    "AppointmentDetails.join_the_call": "제공된 링크를 사용해 참여",
    "AppointmentDetails.join_with_this_link": "이 링크를 사용해 참여",
    "AppointmentDetails.location": "위치",
    "AppointmentDetails.phone_call": "전화 통화",
    "AppointmentDetails.phone_number_ending_in": "{number}(으)로 끝나는 전화번호",
    "AppointmentDetails.pin": "PIN: {pin}",
    "AppointmentDetails.service": "서비스",
    "AppointmentDetails.user": "스태프",
    "AppointmentDetails.video_call": "영상 통화",
    "AppointmentDetails.we_will_call": "제공하신 번호로 전화드리겠습니다.",
    "AppointmentDetails.we_will_call_you": "제공하신 번호로 전화드리겠습니다.",
    "AppointmentDetails.we_will_send_video_info": "모임에 참여하는 방법에 관한 정보를 보내드리겠습니다.",
    "AppointmentSummary.cancel": "취소",
    "AppointmentSummary.default_error_title": "오류가 발생함",
    "AppointmentSummary.location_button": "위치 보기",
    "AppointmentSummary.location_unavailable_description": "이 위치는 더 이상 이용할 수 없습니다. 다른 위치를 선택한 다음에 약속을 예약해야 합니다.",
    "AppointmentSummary.location_unavailable_title": "위치를 더 이상 이용할 수 없음",
    "AppointmentSummary.modal_title": "제목",
    "AppointmentSummary.service_button": "서비스 보기",
    "AppointmentSummary.service_unavailable_description": "이 서비스는 더 이상 이용할 수 없습니다. 요구 사항에 부합하는 다른 서비스가 있는지 확인하거나, 도움이 필요한 경우 {phone}번으로 문의 바랍니다.",
    "AppointmentSummary.service_unavailable_title": "서비스를 더 이상 이용할 수 없음",
    "AppointmentSummary.times_button": "시간 찾기",
    "AppointmentSummary.times_unavailable_description": "{time}은(는) 더 이상 {date}에 이용할 수 없습니다. 다른 시간을 선택한 다음에 약속을 예약해야 합니다.",
    "AppointmentSummary.times_unavailable_title": "시간을 더 이상 이용할 수 없음",
    "AppointmentSummaryExpansionPanel.title": "약속 요약",
    "AttendeeListItem.delete": "추가 참석자 제거",
    "AttendeeListItem.deleted": "{name}님이 제거됨",
    "AttendeeListItem.edit": "추가 참석자 업데이트",
    "BackButton.back": "뒤로",
    "BackButton.back_results": "결과로 돌아가기",
    "BookingValidationErrorModal.default_error_title": "오류가 발생함",
    "BookingValidationErrorModal.location_button": "위치 보기",
    "BookingValidationErrorModal.location_unavailable_description": "이 위치는 더 이상 이용할 수 없습니다. 다른 위치를 선택한 다음에 약속을 예약해야 합니다.",
    "BookingValidationErrorModal.location_unavailable_title": "위치를 더 이상 이용할 수 없음",
    "BookingValidationErrorModal.modal_title": "제목",
    "BookingValidationErrorModal.service_button": "서비스 보기",
    "BookingValidationErrorModal.service_unavailable_description": "이 서비스는 더 이상 이용할 수 없습니다. 요구 사항에 부합하는 다른 서비스가 있는지 확인하거나, 도움이 필요한 경우 {phone}번으로 문의 바랍니다.",
    "BookingValidationErrorModal.service_unavailable_title": "서비스를 더 이상 이용할 수 없음",
    "BookingValidationErrorModal.times_button": "시간 찾기",
    "BookingValidationErrorModal.times_unavailable_description": "{time}은(는) 더 이상 {date}에 이용할 수 없습니다. 다른 시간을 선택한 다음에 약속을 예약해야 합니다.",
    "BookingValidationErrorModal.times_unavailable_title": "시간을 더 이상 이용할 수 없음",
    "CancelModal.additional_details": "추가 세부 정보",
    "CancelModal.appointment_date": "{date}, {time}",
    "CancelModal.cancel_appointment": "약속 취소",
    "CancelModal.keep": "유지",
    "CancelModal.message": "{date}에 예약한 약속을 취소할 수 없습니다.",
    "CancelModal.title": "약속 취소",
    "CancelModal.why_cancel_appointment": "이 약속을 취소하는 이유는 무엇입니까?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "이 약속은 이제 취소할 수 없습니다.",
    "ChangesToYourAppointment.cancellation_policy_header": "취소 정책",
    "ChangesToYourAppointment.cannot_cancel": "이 약속은 취소할 수 없습니다.",
    "ChangesToYourAppointment.cannot_reschedule": "이 약속의 일정을 변경할 수 없습니다.",
    "ChangesToYourAppointment.loading": "로드 중...",
    "ChangesToYourAppointment.meeting_method_subtitle": "약속에 참석할 방식을 변경하고자 하는 경우, 모임 방식을 업데이트하세요.",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "약속에 참석할 방식을 변경하고자 하는 경우, 예약한 시간보다 최대 {duration} 전에 하면 됩니다.",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "모임 방식을 변경할 수 없음",
    "ChangesToYourAppointment.no_meeting_method_options": "다른 옵션을 사용할 수 없습니다",
    "ChangesToYourAppointment.reschedule_appointment": "약속 일정 변경",
    "ChangesToYourAppointment.reschedule_header": "일정 변경",
    "ChangesToYourAppointment.reschedule_no_longer_available": "이 약속은 더 이상 일정을 변경할 수 없습니다.",
    "ChangesToYourAppointment.reschedule_policy_details": "약속의 일정을 변경해야 하는 경우, 예약한 시간보다 최대 {duration} 전에 하면 됩니다.",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "약속의 일정을 변경해야 하는 경우 예약한 시간 전에 하면 됩니다.",
    "ChangesToYourAppointment.title": "약속에 대한 변경 사항",
    "ChangesToYourAppointment.update_meeting_method": "모임 방식 업데이트",
    "ChangesToYourAppointment.update_meeting_method_not_available": "이 약속의 모임 방식은 이제 변경할 수 없습니다.",
    "CheckIn.check_in": "체크인",
    "CheckIn.check_in.aria_label": "약속 확인",
    "CheckIn.confirmation.subtitle": "곧 연결해 드리겠습니다.",
    "CheckIn.confirmation.subtitle_notifiable": "곧 연결해 드리겠습니다. 차례가 되면 알려드리겠습니다.",
    "CheckIn.confirmation.title": "체크인되었습니다!",
    "CheckIn.email": "이메일 주소",
    "CheckIn.fetch_failed": "죄송합니다. 약속을 검색하는 동안 오류가 발생했습니다. 페이지를 새로고침하고 다시 시도하세요.",
    "CheckIn.join_line": "라인에 합류하세요",
    "CheckIn.join_line.aria_label": "약속을 예약하는 대신 볼 수 있는 줄에 합류하려면 여기를 클릭하십시오.",
    "CheckIn.join_line.title": "약속이 없으신가요?",
    "CheckIn.landing_page.title": "당신의 약속을 찾아",
    "CheckIn.next": "다음",
    "CheckIn.next.aria_label": "당신의 약속을 찾아",
    "CheckIn.not_found.email": "죄송합니다. 입력하신 이메일 주소와 일치하는 약속을 찾을 수 없습니다.",
    "CheckIn.not_found.phone": "죄송합니다. 입력하신 전화번호와 일치하는 약속을 찾을 수 없습니다.",
    "CheckIn.phone": "전화 번호",
    "CheckIn.preference": "{preference} 사용하여 약속 조회",
    "CheckIn.subtitle": "이메일 주소 또는 전화번호를 사용하여 약속을 조회합니다.",
    "CheckIn.subtitle_email_only": "이메일 주소를 사용하여 약속 조회",
    "CheckIn.summary.fetch_failed": "죄송합니다. 약속을 확인하는 동안 오류가 발생했습니다. 페이지를 새로고침하고 다시 시도하세요.",
    "CheckIn.summary.sms_updates": "문자 메시지 업데이트 받기",
    "CheckIn.summary.sms_updates.dialog": "문자 메시지 업데이트를 받으시겠습니까?",
    "CheckIn.summary.sms_updates.dialog.accept": "예, 업데이트를 문자로 받기",
    "CheckIn.summary.sms_updates.dialog.deny": "아니요, 업데이트 받지 않고 체크인",
    "CheckIn.summary.sms_updates.message": "귀하의 {phone} 차례가 되면 업데이트를 보내드리겠습니다.",
    "CheckIn.summary.subtitle": "아래에서 예약 세부 정보를 확인하고 체크인하십시오.",
    "CheckIn.summary.title": "확인하고 체크인",
    "CheckIn.title": "약속 찾아보기",
    "CircularProgress.loading": "로드 중",
    "Common.appointment_details": "약속 세부 정보",
    "CookieNotice.accept": "동의",
    "CookieNotice.closed": "쿠키 면책 조항이 닫혔습니다.",
    "CookieNotice.ignore": "무시하다",
    "CookieNotice.learn_more": "자세히 알아보기",
    "CookieNotice.message": "당사는 사용자가 당사 웹사이트에서 최선의 경험을 하도록 보장하기 위해 쿠키를 사용합니다.",
    "CookieNotice.title": "쿠키 면책조항",
    "CountrySelectInput.label": "국가",
    "CountrySelectInput.most_popular": "인기 항목",
    "CountryTimezoneModal.description": "시간은 모두 아래에서 선택한 표준시간대에 따라 표시됩니다.",
    "CountryTimezoneModal.title": "표준시간대 선택",
    "DateTime.locale_badge_label": "{name}님이 사용하는 언어: {language}",
    "DateTime.locale_badge_tooltip": "사용하는 언어: {language}",
    "DateTime.mobile_header": "약속을 언제로 정하시겠습니까?",
    "DateTime.no_language_preference": " ({locale} 없음)",
    "DateTime.no_times_available_on_date": "이용 가능한 시간이 없습니다.",
    "DateTime.select_time": "시간 선택",
    "DateTime.select_user": "스태프 멤버 선택",
    "DateTime.showing_times_for": "시간 표시 대상",
    "DateTime.times_available_for_people_speaking": "{locale}에 {date}에 대해 이용 가능한 시간",
    "DateTime.times_available_on_date": "{date}에 이용 가능한 시간",
    "DateTime.timezone_header": "시간은 {timezone} 기준으로 표시됨",
    "DateTime.user_mobile_header": "{name}님과 언제 모임을 가지시겠습니까?",
    "DateTime.user_schedule": "{name}님과 시간 예약",
    "DateTime.who_speaks": " 사용하는 언어: {locale}",
    "Dates.all_day_date": "{date}, 종일",
    "Dates.all_day_today": "오늘, 종일",
    "Dates.plural.day": "{count, plural, one {일} other {일}}",
    "Dates.plural.month": "{count, plural, one {달} other {달}}",
    "Dates.plural.week": "{count, plural, one {주} other {주}}",
    "Dates.plural.year": "{count, plural, one { 년 } other { 년 }}",
    "Dates.range_today": "오늘, {start} - {end}",
    "Dates.range_today_with_timezone": "오늘, {start} - {end}({timezone})",
    "Dates.today_with_timezone": "오늘, {date}({timezone})",
    "DesktopUsers.locale_badge_tooltip": "사용하는 언어: {language}",
    "Details.form_header": "세부 정보 입력",
    "DetailsForm.add": "추가",
    "DetailsForm.add_attendee": "+ 추가 참석자 추가",
    "DetailsForm.add_attendee_title": "참석자 추가",
    "DetailsForm.additional_attendees": "추가 참석자",
    "DetailsForm.additional_attendees_limit": "(최대 {limit})",
    "DetailsForm.address": "주소",
    "DetailsForm.agree_tos": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_privacy": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}, {vendor}의 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_tos": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}, {vendor}의 {vendorTerms}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor": "약속을 예약하면 Coconut Software의 {terms} 및 {privacy}, {vendor}의 {vendorTerms} 및 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "약속을 예약하면 {vendor}의 {vendorTerms} 및 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "약속을 예약하면 {vendor}의 {vendorPrivacy}에 동의하는 것이 됩니다.",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "약속을 예약하면 {vendor}의 {vendorTerms}에 동의하는 것이 됩니다.",
    "DetailsForm.appointment_policy_action": "약속 예약",
    "DetailsForm.cell_phone": "휴대전화 번호",
    "DetailsForm.cell_phone_context": "이 번호로 전화드리겠습니다.",
    "DetailsForm.city": "시",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.country": "국가",
    "DetailsForm.duplicate_attendee": "이 이름과 이메일을 사용하는 참석자가 이미 있음",
    "DetailsForm.edit": "저장",
    "DetailsForm.edit_attendee_title": "참석자 편집",
    "DetailsForm.email": "이메일",
    "DetailsForm.explicit_compliance_agreement_label": "동의",
    "DetailsForm.first_name": "이름",
    "DetailsForm.home_phone": "자택 전화번호",
    "DetailsForm.last_name": "성",
    "DetailsForm.notes": "약속 세부 정보",
    "DetailsForm.postal_code": "우편번호",
    "DetailsForm.privacy": "개인정보 취급방침",
    "DetailsForm.province": "주\/도",
    "DetailsForm.receive_sms": "문자 메시지로 약속 알림 받기",
    "DetailsForm.receive_sms_required": "이 정보를 사용해 약속에 관한 중요한 업데이트를 보내드리겠습니다.",
    "DetailsForm.select_country": "국가를 선택하세요.",
    "DetailsForm.select_province": "주\/도를 선택하세요.",
    "DetailsForm.select_state": "주\/도를 선택하세요.",
    "DetailsForm.sms_error_title": "문자 메시지를 수신하려면 다시 옵트인합니다.",
    "DetailsForm.state": "주\/도",
    "DetailsForm.submit": "계속",
    "DetailsForm.terms_of_use": "이용약관",
    "DetailsForm.walkin_policy_action": "줄을 서다",
    "DetailsForm.work_phone": "직장 전화번호",
    "Dialog.clear": "지우기",
    "Dialog.confirm": "확인",
    "Dropzone.delete": "삭제",
    "Dropzone.instructions": "여기에 파일을 끌어오거나 <span>검색해서 업로드<\/span>",
    "Duration.days": "{duration, plural, one {#일} other {#일}}",
    "Duration.hours": "{duration, plural, one {#시간} other {#시간}}",
    "Duration.minutes": "{duration, plural, one {#분} other {#분}}",
    "Duration.weeks": "{duration, plural, one {#주} other {#주}}",
    "EmptyState.contact_vendor": "다시 시도하거나 문의하세요.",
    "FastRoute.book_anyway": "대신 약속 예약",
    "FastRoute.skip_the_wait": "대기 건너뛰기",
    "FastRoute.title": "약속할 필요 없음",
    "Feedback.already_submitted": "피드백이 이미 제출되었습니다. 감사합니다!",
    "Feedback.appointment_review_subtitle": "약속에 대해 어떻게 느꼈는지 알려주십시오.",
    "Feedback.appointment_review_title": "약속 피드백",
    "Feedback.close": "피드백 섹션 닫기",
    "Feedback.error": "제출하기 전에 평점을 기재하세요.",
    "Feedback.experience": "내 경험",
    "Feedback.experience_error_message": "피드백은 필수 입력란입니다. 길이가 144자보다 큰지 확인하십시오.",
    "Feedback.experience_required": "당신의 경험 (필수)",
    "Feedback.google_description": "당사는 사용자의 피드백을 진심으로 중시하며 다른 고객에게도 유익할 것이라고 생각합니다. 잠시 시간을 들여 Google 리뷰를 통해 당사와의 경험에 관해 다른 고객에게도 알려주실 의향이 있다면 아래의 버튼을 클릭하세요. 곧 리디렉션됩니다.",
    "Feedback.google_submit": "Google 리뷰 제출",
    "Feedback.neutral": "보통",
    "Feedback.new_title": "피드백",
    "Feedback.rating": "평점(필수)",
    "Feedback.response_prompt": "어떤 면에서 개선의 여지가 있는지 알려주세요.",
    "Feedback.send": "피드백 보내기",
    "Feedback.sending": "피드백 보내는 중",
    "Feedback.staff_review_subtitle": "오늘 저희 직원이 어떻게 했는지 말씀해 주세요.",
    "Feedback.staff_review_title": "직원 피드백",
    "Feedback.subtitle": "피드백은 익명 처리됩니다. 이 정보는 예약 경험을 개선하는 데 사용됩니다.",
    "Feedback.success": "피드백 제출이 완료되었습니다. 감사합니다.",
    "Feedback.thank_you": "피드백을 주셔서 감사합니다.",
    "Feedback.title": "이 약속을 예약한 경험이 어땠나요?",
    "Feedback.very_dissatisfied": "매우 불만족",
    "Feedback.very_satisfied": "매우 만족",
    "FetchSlots.error": "문제가 발생했습니다. 나중에 다시 시도해 주세요.",
    "FindAvailableDate.earliest_available": "가장 빠른 이용 가능",
    "FindAvailableDate.finding_first_time": "첫 번째 가능한 시간 찾기",
    "FindAvailableDate.finding_next_time": "다음 사용 가능한 시간 찾기",
    "FindAvailableDate.finding_time": "가능한 시간 찾기",
    "FindAvailableDate.first_available_date": "최대한 빨리",
    "FindAvailableDate.next_available": "다음 사용 가능",
    "FindAvailableDate.next_available_date": "다음 가능한 날짜",
    "FindAvailableDate.tooltip_error": "날짜를 가져오는 중에 오류가 발생했습니다.",
    "FindAvailableDate.tooltip_fetching": "날짜를 가져오는 중...",
    "Form.field_error.cell_phone_characters": "유효한 휴대폰 번호를 입력하세요.",
    "Form.field_error.cell_phone_length": "9~20자 길이의 유효한 휴대전화 번호를 입력하세요.",
    "Form.field_error.email_structure": "유효한 이메일 주소를 입력하세요.",
    "Form.field_error_max_length": "이 필드는 {max} 자를 초과할 수 없습니다.",
    "Form.field_error_min_length": "이 필드는 {min} 자보다 작을 수 없습니다.",
    "Form.field_required": "이 필드는 필수입니다.",
    "Form.optional": "(선택 사항)",
    "GenericError.subtitle": "요청하신 페이지를 찾을 수 없음",
    "GenericError.title": "죄송합니다. 문제가 발생했습니다.",
    "Input.additional_input": "추가 메시지",
    "Invitations.verification_failed": "제공한 이메일 주소가 당사 기록과 일치하지 않습니다.",
    "Label.phone_number": "전화 번호",
    "LandingChoices.book_appointment_subtitle": "누군가를 만나기 위한 특정 날짜와 시간 선택",
    "LandingChoices.book_appointment_title": "약속 예약",
    "LandingChoices.callback_subtitle": "오늘 최대한 빨리 누군가와 만나기",
    "LandingChoices.callback_title": "라인 참여",
    "LandingChoices.check_in_subtitle": "사전에 예약한 약속 때문에 온 경우",
    "LandingChoices.check_in_title": "체크인",
    "LandingChoices.choose_another_location_subtitle": "다른 위치를 검색하려면 여기를 클릭하십시오.",
    "LandingChoices.prefill_title": "{name}님, 환영합니다! 무엇을{space}하시겠습니까?",
    "LandingChoices.prefill_title.service": "{name}님, 환영합니다! 어떤 방식으로 {service} 약속을 예약하시겠습니까?",
    "LandingChoices.private_location": "이 위치는 비공개입니다.",
    "LandingChoices.title": "무엇을{space}하시겠습니까?",
    "LandingChoices.title.service": "어떤 방식으로 {service} 약속을 예약하시겠습니까?",
    "LandingChoices.virtual_location": "가상 위치",
    "LandingChoices.welcome_to": "환영합니다",
    "Languages.chinese": "중국인",
    "Languages.english": "영어",
    "Languages.french": "프랑스어",
    "Languages.korean": "한국어",
    "Languages.language": "언어",
    "Languages.polish": "폴란드어",
    "Languages.portuguese": "포르투갈어",
    "Languages.russian": "러시아어",
    "Languages.spanish": "스페인어",
    "LobbyBanner.button": "라인 참여",
    "LobbyBanner.button_description": "약속을 예약하는 대신 볼 수 있는 줄에 합류하려면 여기를 클릭하십시오.",
    "LobbyBanner.phone_button": "콜백 요청",
    "LobbyBanner.phone_button_description": "약속을 예약하는 대신 전화 콜백을 예약하려면 여기를 클릭하십시오.",
    "LobbyBanner.title": "약속하지 않아도 됩니다!",
    "LobbyBanner.wait_time": "예상 대기 시간: {time}분",
    "Location.previously_booked": "이전에 예약함",
    "Location.search_area": "이 영역 검색",
    "Location.select_location": "위치 선택",
    "Location.select_location_prefill": "{name}님, 위치를 선택하세요.",
    "LocationDetails.continue": "계속",
    "LocationDetails.location_details": "위치 세부 정보",
    "LocationDetails.see_all_locations": "모든 위치 보기",
    "LocationDetails.select_location": "이 위치 선택",
    "LocationDirectionsButton.text": "길 찾기",
    "LocationHours.closed": "종료됨",
    "LocationHours.title": "운영 시간",
    "LocationIdentifier.empty_error": "위치 번호를 입력하세요.",
    "LocationIdentifier.help_text": "표지에 표시된 위치 번호를 입력하여 시작",
    "LocationIdentifier.location_number": "위치 번호",
    "LocationIdentifier.not_found_error": "이 항목과 일치하는 위치 없음",
    "LocationIdentifier.title": "환영합니다!",
    "LocationList.empty_state_message": "결과를 찾을 수 없음",
    "LocationList.empty_state_secondary": "검색 범위 넓혀 보기",
    "LocationList.finding_locations": "가까운 위치 찾는 중",
    "LocationList.near_me": "가까운 위치",
    "LocationList.no_locations": "위치를 찾을 수 없음",
    "LocationList.title": "위치 선택",
    "LocationList.toggle_list": "목록 표시",
    "LocationList.toggle_map": "지도 표시",
    "LocationPhoneCallButton.text": "{number}에 전화 걸기",
    "LocationStepTitle.preferred_location": "선호하는 위치는 무엇입니까?",
    "LocationStepTitle.preferred_location_helper": "선택한 위치의 스태프와 매칭해 드리겠습니다.",
    "LoginWithGoogle.showing_times_that_match": "사용자의 일정에 맞는 시간 표시 중",
    "LoginWithGoogle.sign_in": "Google로 로그인",
    "LoginWithGoogle.sign_in_details": "사용자의 일정에 맞는 시간 표시 목적",
    "Manage.appointment_details": "약속 세부 정보",
    "Manage.book_another": "다른 약속 예약",
    "Manage.can_never_cancel": "온라인 관리를 이용할 수 없습니다. 변경 사항을 적용하려면 당사에 문의하세요.",
    "Manage.cancel": "약속 취소",
    "Manage.cancellation_policy": "취소 정책",
    "Manage.cancellation_policy_details": "약속에 참석할 수 없는 경우, 예약한 시간보다 최대 {duration} 전에 취소하세요.",
    "Manage.cancellation_policy_details_no_limit": "약속에 참석할 수 없는 경우, 예약한 시간 전에 취소하세요.",
    "Manage.cancelled": "약속이 취소됨",
    "Manage.cancelled_successfully": "약속이 취소됨",
    "Manage.cannot_cancel_anymore": "지금은 온라인 관리를 이용할 수 없습니다. 변경 사항을 적용하려면 당사에 문의하세요.",
    "Manage.confirmation": "확인",
    "Manage.confirmation_email": "약속 세부 정보와 약속에 관련된 각종 추가 정보를 함께 기재한 이메일을 보내드렸습니다.",
    "Manage.details": "세부 정보",
    "Manage.in_the_past": "약속이 과거 날짜임",
    "Manage.reschedule": "일정 변경",
    "Manage.restart": "홈페이지에서 다시 시작",
    "Manage.successfully_booked": "약속이 예약됨",
    "Manage.updated_successfully": "약속이 업데이트됨",
    "ManageAppointmentDetails.details_below": "약속의 세부 정보는 아래와 같습니다. 사본을 이메일로 보내드렸습니다.",
    "ManageAppointmentDetails.done_title": "완료되었습니다!",
    "ManageAppointmentDetails.successfully_booked": "약속이 예약되었습니다.",
    "ManageAppointmentDetails.successfully_rescheduled": "약속의 일정이 변경되었습니다.",
    "ManageAppointmentDetails.your_appointment": "나의 약속",
    "MeetingMethodForm.appointment_date": "{date}, {time}",
    "MeetingMethodForm.keep": "유지",
    "MeetingMethodForm.message.in_person": "{date}에 대면 모임으로 예약된 약속의 모임 방식을 업데이트합니다.",
    "MeetingMethodForm.message.off_site": "{date}에 원하는 장소에서 모임으로 예약된 약속의 모임 방식을 업데이트합니다.",
    "MeetingMethodForm.message.phone_call": "{date}에 전화 통화로 예약된 약속의 모임 방식을 업데이트합니다.",
    "MeetingMethodForm.message.video_call": "{date}에 영상 통화로 예약된 약속의 모임 방식을 업데이트합니다.",
    "MeetingMethodForm.new_method_label": "약속에 어떻게 참석하시겠습니까?",
    "MeetingMethodForm.title": "모임 방식 업데이트",
    "MeetingMethodForm.update_method": "모임 방식 업데이트",
    "MeetingMethodInfoBanner.only_phone_available": "이 모임은 전화 통화로만 이용할 수 있음",
    "MeetingMethodInfoBanner.only_video_available": "이 모임은 영상 통화로만 이용할 수 있음",
    "MeetingMethods.at_location_primary": "대면",
    "MeetingMethods.at_location_secondary": "위치 중 하나 선택",
    "MeetingMethods.header": "약속에 어떻게 참석하시겠습니까?",
    "MeetingMethods.off_site_primary": "오프사이트",
    "MeetingMethods.off_site_secondary": "직접 만남",
    "MeetingMethods.phone_call_primary": "전화",
    "MeetingMethods.phone_call_secondary": "당사에서 전화",
    "MeetingMethods.video_call_primary": "동영상",
    "MeetingMethods.video_call_secondary": "링크 전송",
    "MonthlyDatePicker.next_month": "다음 달",
    "MonthlyDatePicker.previous_month": "이전 달",
    "Navigation.aria_label.back": "이전 단계로 돌아가기",
    "Navigation.aria_label.call": "통화 위치",
    "Navigation.aria_label.card": "{navItem} 선택",
    "Navigation.aria_label.date": "{date} 선택",
    "Navigation.aria_label.directions": "길 찾기 조회",
    "Navigation.aria_label.language": "{language}를 사용하는 스태프",
    "Navigation.aria_label.language_specific": "특정 스태프",
    "NoLocationPreferenceButton.skip": "건너뛰기",
    "NoLocationPreferenceButton.title": "선호 사항 없음",
    "NoTimesAvailable.no_times_in_month": "{month}에는 시간 없음",
    "NoTimesAvailable.no_times_in_week": "이번 주에는 시간 없음",
    "NoTimesAvailable.view_next_month": "다음 달 조회",
    "NoTimesAvailable.view_next_week": "다음 주 조회",
    "NotAvailable.subtitle": "현재 약속을 받지 않고 있음",
    "NotAvailable.title": "이용할 수 없음",
    "OtherLocationsTimeChunks.available_times_title": "다른 위치의 이용 가능한 시간",
    "OtherLocationsTimeChunks.no_available_times": "다른 위치에 이용 가능한 시간 없음",
    "OtherLocationsTimeChunks.view_available_times": "다른 위치의 이용 가능한 시간 조회 ",
    "OtherLocationsTimeChunks.view_more": "더 보기",
    "Pages.country": "국가",
    "Pages.staff_preference": "스태프 기본 설정",
    "Pages.supported_language_preference": "언어",
    "Pages.timezones": "시간대",
    "PositionErrorAlert.denied": "사용자의 허가를 받지 못해 위치를 가져오지 못했습니다. 이 사이트에 대한 브라우저 설정을 확인하세요.",
    "PositionErrorAlert.retry": "다시 시도",
    "PositionErrorAlert.unable": "위치를 가져오지 못했습니다.",
    "RadioButton": "라디오 버튼",
    "Reschedule.appointment_details": "약속 세부 정보",
    "Reschedule.confirm_time": "시간 확인",
    "Reschedule.error": "약속의 일정을 변경할 수 없음",
    "Reschedule.label": "일정 변경 확인",
    "Reschedule.reschedule": "일정 변경",
    "Security.continue": "계속",
    "Security.email_verification": "이메일 확인",
    "Security.please_verify": "약속을 예약하기 전에 이메일 주소를 확인하세요.",
    "SelectInput.default": "선택",
    "Service.all_categories_title": "모든 카테고리",
    "Service.category_title": "카테고리",
    "Service.five_minutes": "< 5 minutes",
    "Service.hours": "{hours, plural, one {#시간} other {#시간}}",
    "Service.minutes": "{minutes, plural, one {#분} other {#분}}",
    "Service.mobile_header": "어느 분야에 도움이 필요하십니까?",
    "Service.no_services": "서비스를 찾을 수 없음",
    "Service.read_less": "간단히 표시",
    "Service.read_more": "자세히 표시",
    "Service.select_title": "서비스 선택",
    "Service.select_title_prefill": "{name}님,{break}서비스를 선택하세요.",
    "Service.service_count": "{number, plural, one {#개 서비스} other {#개 서비스}}",
    "Service.uncategorized_title": "기타",
    "ServiceHeader.group": "그룹",
    "ServiceInstructions.title": "지침",
    "ShortcutExpired.message": "이 링크는 만료됨",
    "Sidebar.footer": "제공",
    "Sidebar.title": "약속 예약",
    "Sidebar.title_check_in": "체크인",
    "Sidebar.title_join_the_line": "줄을 서다",
    "SpecificUserLanguagePreferences.title": "언어 기본 설정",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "{current}\/{end}단계",
    "Steps.check_in": "체크인",
    "Steps.check_in_description": "<p> 약속을 확인하십시오. <\/p> <p> 직원이 서비스를 제공할 준비가 되면 알려드리겠습니다. <\/p>",
    "Steps.date_time": "날짜 및 시간",
    "Steps.date_time_no_wait_times": "직원이 서비스를 준비하자마자",
    "Steps.date_time_with_wait_times": "예상 대기 시간: {time} 분",
    "Steps.date_time_with_zero_wait_times": "지금은 대기 시간이 없습니다",
    "Steps.details": "세부 정보",
    "Steps.details_description": "<p> 오늘 표시할 자신을 추가하십시오. <\/p> <p> 직원이 서비스를 제공할 준비가 되면 다시 오시도록 알려드리겠습니다. <\/p>",
    "Steps.location": "위치",
    "Steps.meeting_details": "모임 세부 정보",
    "Steps.meeting_methods": "모임 방식",
    "Steps.no_staff_warning": "줄을 서면 첫 번째 가능한 직원이 서비스를 제공합니다. 이전에 선택한 {name} 가) 아닐 수 있습니다.",
    "Steps.service": "서비스",
    "Steps.services": "서비스",
    "Steps.staff": "스태프",
    "Steps.staff_preference": "스태프 기본 설정",
    "Steps.summary": "요약",
    "Steps.your_details": "내 세부 정보",
    "Summary.book_appointment": "약속 예약",
    "Summary.header": "거의 다 됐습니다! 검토하고 예약",
    "Summary.join_the_line": "줄을 서다",
    "Summary.please_review": "검토해주세요.",
    "SummaryExpansionPanel.title": "요약",
    "SupportedLanguagePreferencePicker.anyone": "모든 사용자",
    "SupportedLanguagePreferencePicker.anyone_available": "이용 가능한 모든 사용자",
    "SupportedLanguagePreferencePicker.assigned_staff": "할당된 스태프",
    "SupportedLanguagePreferencePicker.language_preference": "언어 기본 설정",
    "SupportedLanguagePreferencePicker.specific_language": "{language}를 사용하는 모든 사용자",
    "Svg.alt_text.appointment": "약속",
    "Svg.alt_text.back": "뒤로",
    "Svg.alt_text.back_chevron": "뒤로",
    "Svg.alt_text.checkbox_not_selected": "확인란 선택 안 함",
    "Svg.alt_text.checkbox_selected": "확인란 항목 선택함",
    "Svg.alt_text.checkmark": "체크표시",
    "Svg.alt_text.clock": "시계",
    "Svg.alt_text.coconut_logo": "Coconut 소프트웨어 로고",
    "Svg.alt_text.completed": "완전한",
    "Svg.alt_text.continue": "계속",
    "Svg.alt_text.country_selected": "국가 선택됨",
    "Svg.alt_text.down_chevron": "더 보기",
    "Svg.alt_text.drop_down_arrow": "드롭다운 화살표",
    "Svg.alt_text.edit": "편집",
    "Svg.alt_text.edit_timezone": "시간대 변경",
    "Svg.alt_text.expand": "펼치기",
    "Svg.alt_text.expanded": "펼침",
    "Svg.alt_text.forward_chevron": "앞으로",
    "Svg.alt_text.hide_location_details": "위치 세부 정보 숨기기",
    "Svg.alt_text.in_person": "위치",
    "Svg.alt_text.item_selected": "선택한 항목",
    "Svg.alt_text.language": "언어",
    "Svg.alt_text.location_pin": "위치",
    "Svg.alt_text.near_me": "주변",
    "Svg.alt_text.next_month": "다음 달",
    "Svg.alt_text.next_week": "다음 주",
    "Svg.alt_text.person": "인물",
    "Svg.alt_text.phone": "전화",
    "Svg.alt_text.previous_month": "이전 달",
    "Svg.alt_text.previous_week": "이전 주",
    "Svg.alt_text.search": "검색",
    "Svg.alt_text.select_category": "카테고리 선택",
    "Svg.alt_text.select_language": "언어 선택",
    "Svg.alt_text.show_location_details": "위치 세부 정보 표시",
    "Svg.alt_text.timezone": "시간대",
    "Svg.alt_text.timezone_selected": "시간대 선택됨",
    "Svg.alt_text.up_chevron": "간단히 보기",
    "Svg.alt_text.upload": "업로드",
    "Svg.alt_text.user_selected": "사용자가 선택함",
    "Svg.alt_text.video": "동영상",
    "Svg.alt_text.walk_in": "워크인 서비스 아이콘",
    "TimeChunk.no_spots": "남은 스폿이 없음",
    "TimeChunk.spots_remaining": "{number, plural, one {#스폿 남음} other {#개 스폿 남음}}",
    "TimeChunk.unlimited_spots": "스폿 무제한",
    "TimeChunks.afternoon": "오후",
    "TimeChunks.evening": "저녁",
    "TimeChunks.morning": "아침",
    "TimeChunks.no_available_times": "이용 가능한 시간 없음",
    "TimeChunks.no_available_times_in_month": "이번 달 이용 가능 여부가 없습니다.",
    "TimeChunks.no_available_times_in_range": "{range} {rangeUnit} 기간 내에 이용 가능 여부를 찾을 수 없습니다.",
    "TimeChunks.no_available_times_in_range_month": "{range} 개월 이내에 이용 가능 여부를 찾을 수 없습니다.",
    "TimeChunks.no_available_times_in_range_week": "{range} 주 동안 이용 가능 여부를 찾을 수 없습니다.",
    "TimeChunks.no_available_times_in_week": "이번 주에는 이용 가능 여부가 없습니다.",
    "TimeChunks.no_available_times_on": "{date}에 이용 가능한 시간 없음",
    "TimeChunks.select_another_day": "다른 날을 선택하여 이용 가능한 시간 찾기",
    "TimeChunks.select_another_month": "한 달 더 시도.",
    "TimeChunks.select_another_range": "다른 직원이나 위치를 시도해 보세요.",
    "TimeChunks.select_another_week": "한 주 더 시도.",
    "TimeChunks.select_user": "스태프 멤버를 선택하여 이용 가능 여부 보기",
    "TimezoneSelectInput.label": "표준시간대 지역",
    "TimezoneSelectInput.required": "시간대는 필수입니다.",
    "TimezonesShownIn.label": "시간 표시 기준",
    "TooLateToCancelModal.message": "이 약속은 이제 온라인으로 취소할 수 없습니다.",
    "TooLateToCancelModal.title": "취소 가능한 시간 지남",
    "Ui.archive": "보관",
    "Ui.back": "뒤로",
    "Ui.back_to_previous": "다음으로 돌아가기: {previous}",
    "Ui.cancel": "취소",
    "Ui.clear": "지우기",
    "Ui.close": "종료",
    "Ui.confirm": "확인",
    "Ui.continue": "계속",
    "Ui.current_selection": "현재 선택 항목",
    "Ui.details_about_name": "{name} 관련 세부 정보",
    "Ui.details_about_name_and_address": "{name} 관련 세부 정보({address})",
    "Ui.dismiss": "닫기",
    "Ui.do_not_continue": "계속",
    "Ui.hours_of_operation": "영업시간",
    "Ui.near_me": "주변",
    "Ui.next": "다음",
    "Ui.ok": "확인",
    "Ui.search": "검색",
    "Ui.search.instructions": "입력을 시작해 주세요",
    "Ui.search.no_results_found": "결과를 찾을 수 없음",
    "Ui.search.plain": "찾다",
    "Ui.select": "선택",
    "Ui.select_name": "{name} 선택",
    "Ui.selected": "선택됨",
    "Ui.sign_out": "로그아웃",
    "Ui.type_here": "여기에 입력하세요...",
    "Ui.undo": "실행 취소",
    "UserPreference.anyone": "모든 사용자",
    "UserPreference.anyone_available": "이용 가능한 모든 사용자",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "{language}를 사용하는 사람만 할당",
    "UserPreference.assign_me_title": "{language}를 사용하는 모든 사용자",
    "UserPreference.assigned_title": "할당된 스태프",
    "UserPreference.header": "선호하는 스태프 멤버가 있습니까?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "선호 사항 없음",
    "UserPreference.random_subtitle": "선호 사항 없음",
    "UserPreference.random_title": "이용 가능한 모든 사용자",
    "UserPreference.see_all_staff": "모든 스태프 보기",
    "UserPreference.specific_subtitle": "만날 대상을 정확히 알고 있음",
    "UserPreference.specific_title": "특정 스태프 멤버",
    "Validator.address": "주소",
    "Validator.cell_phone": "휴대전화 번호",
    "Validator.city": "시",
    "Validator.country": "국가",
    "Validator.email": "이메일",
    "Validator.first_name": "이름",
    "Validator.home_phone": "자택 전화",
    "Validator.last_name": "성",
    "Validator.meeting_method": "모임 방식",
    "Validator.notes": "메모",
    "Validator.phone_code": "잘못된 국가 코드",
    "Validator.phone_country_code": "1~3자리 국가 코드를 입력하고 '+' 다음에 전화번호를 입력하세요.",
    "Validator.phone_length": "잘못된 전화 길이",
    "Validator.phone_valid": "유효한 전화번호를 입력하세요.",
    "Validator.postal_code": "우편번호",
    "Validator.province": "주\/도",
    "Validator.state": "주\/도",
    "Validator.work_phone": "직장 전화",
    "Validator.zip_code": "우편번호",
    "ViewOnMapButton.title": "지도에서 보기",
    "WaitTime.loading": "워크인 대기 시간 가져오는 중...",
    "WaitTime.no_walkins_currently": "현재 워크인 예약을 받지 않음",
    "WaitTime.wait": "현재 워크인 대기 시간: {wait}",
    "WaitTime.wait_time": "현재 워크인 대기 시간: {waitTime}",
    "WeeklyDatePicker.next_week": "다음 주",
    "WeeklyDatePicker.previous_week": "이전 주",
    "WhatNow.homepage": "{vendor} 웹사이트로 이동",
    "WhatNow.title": "이제 어떻게 되나요?"
}