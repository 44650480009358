import classNames from 'classnames';
import { Visibilities } from 'coconut-open-api-js';
import { isPast, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { LANGUAGES, SHORTCUTS, USER_PREFERENCE } from '../constants';
import { FeatureContext } from '../contexts/FeatureContext';
import { LocaleContext } from '../contexts/LocaleContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { UsersContext } from '../contexts/UsersContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Api from '../helpers/Api';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import Resources from '../helpers/Resources';
import Shortcuts from '../helpers/Shortcuts';
import Slots from '../helpers/Slots';
import SpacetimeShape from '../shapes/SpacetimeShape';
import Button from './Button';
import CircularProgress from './CircularProgress';
import TimeChunk from './TimeChunk';
import Typography from './Typography';

const LOCATION_LIMIT = 3;

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    '&:focus': {
      outline: 0,
      boxShadow: theme.shadows.input,
      borderColor: theme.palette.secondary[500],
    },
  },
  mobilePadding: {
    padding: '0 1.25rem',
  },
  mobileViewMoreButton: {
    marginBottom: '1.5rem',
  },
  margin: {
    marginTop: '2.5rem',
  },
  title: {
    marginBottom: '1.5rem',
  },
}));

export const ActionTypes = {
  SetLoading: 0,
  AddSlots: 1,
  ClearSlots: 2,
};

const reducers = {
  [ActionTypes.SetLoading]: (state, action) => ({
    ...state,
    loading: action.loading,
  }),
  [ActionTypes.AddSlots]: (state, { loading = state.loading, slots }) => ({
    ...state,
    loading,
    slots: {
      ...state.slots,
      ...slots,
    },
  }),
  [ActionTypes.ClearSlots]: (state) => ({ ...state, slots: {} }),
};

const runReducer = (state, action) => reducers[action.type](state, action);

const OtherLocationsTimeChunks = ({
  bordered,
  different,
  locationHasSlots,
  selected,
  selectTime,
  specificUser,
}) => {
  const OpenApi = Open.api();
  const classes = useStyles();

  const mode = useContext(ViewModeContext);
  const [locale] = useContext(LocaleContext);
  const features = useContext(FeatureContext);
  const [timezone] = useContext(TimezoneContext);
  const { supportedLanguages } = useContext(UsersContext);

  const [locations, setLocations] = useState(null);
  const [{ loading, slots }, dispatch] = useReducer(runReducer, {
    loading: false,
    slots: {},
  });
  const [
    {
      additionalUsers,
      googleUser,
      location,
      locationCategory,
      meetingMethod,
      region,
      service,
      settings,
      shortcuts,
      user,
      userCategory,
      userPreference,
    },
    setSelections,
  ] = useContext(SelectionContext);

  const nextLocationIndex = useRef(null);

  const fetchLocations = () => {
    setLocations(null);

    let details;

    if (region) {
      if (region.country) {
        details = {
          country: region.id,
          regionless: true,
        };
      } else {
        details = Shortcuts.details({ region });
      }
    }

    Api.locale(locale)
      .locations()
      .all({
        filters: {
          assigned: true,
          coordinates: {
            latitude: location.coordinates?.lat,
            longitude: location.coordinates?.lng,
          },
          details,
          meetingMethod,
          service,
          settings,
          user,
          userCategory,
        },
      })
      .then((data) => {
        const locations = data.filter(({ id }) => id !== location.id);

        setLocations(locations.map(Resources.formatV3Location));
      });
  };

  const fetchSlots = ({ page = 1 }) => {
    if (
      nextLocationIndex.current === null ||
      !locations[nextLocationIndex.current]
    ) {
      dispatch({ type: ActionTypes.SetLoading, loading: false });

      return;
    }

    const start = selected.startOf('day').format();
    const end = selected.add(1, 'day').startOf('day').format();

    const preferred = userPreference || {
      id: specificUser ? null : USER_PREFERENCE.RANDOM,
    };
    const locationId = locations[nextLocationIndex.current].id;

    dispatch({ type: ActionTypes.SetLoading, loading: true });

    if (
      googleUser?.refreshing ||
      (googleUser?.expires_at && isPast(parseISO(googleUser.expires_at)))
    ) {
      setSelections({ googleUser: { ...googleUser, refreshing: true } });

      return;
    }

    OpenApi.slots()
      .in(timezone)
      .for(service.id)
      .when(additionalUsers.length, (api) =>
        api.attendedBy(additionalUsers.map(({ id }) => id)),
      )
      .by(Item.get(user || {}, 'id'))
      .when(userCategory, (api) => api.withinUserCategory(userCategory.id))
      .when(!settings.preferred_location && locationCategory?.id, (api) =>
        api.withinLocationCategory(locationCategory.id),
      )
      .when(meetingMethod, (api) => api.method(meetingMethod))
      .at(locationId)
      .between(start, end)
      .when(
        // We are temporarily ignoring the destructuring-assignment rule explicitly.
        // There is a bug that was solved in a newer version of this plugin which
        // we will eventually be able to upgrade to once we can move off of
        // the current version of NodeJS in use.
        //
        // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
        //
        // eslint-disable-next-line react/destructuring-assignment
        features.spokenLanguages && supportedLanguages?.includes(preferred.id),
        (api) => api.supporting([preferred.id]),
      )
      .when(
        // We are temporarily ignoring the destructuring-assignment rule explicitly.
        // There is a bug that was solved in a newer version of this plugin which
        // we will eventually be able to upgrade to once we can move off of
        // the current version of NodeJS in use.
        //
        // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
        //
        // eslint-disable-next-line react/destructuring-assignment
        !features.spokenLanguages &&
          Object.keys(LANGUAGES).includes(preferred.id),
        (api) => api.supporting([preferred.id]),
      )
      .when(preferred.id === USER_PREFERENCE.RANDOM, (api) =>
        api.supporting(null),
      )
      .when(settings?.invite_only_resources, (api) => api.withInviteOnly())
      .when(window.location.pathname === '/reschedule', (api) =>
        api.visibility(Visibilities.ALL),
      )
      .when(
        // We are temporarily ignoring the destructuring-assignment rule explicitly.
        // There is a bug that was solved in a newer version of this plugin which
        // we will eventually be able to upgrade to once we can move off of
        // the current version of NodeJS in use.
        //
        // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
        //
        // eslint-disable-next-line react/destructuring-assignment
        features.clientGoogleLogin && googleUser?.matchAvailability,
        (api) => api.google(googleUser.token),
      )
      .get()
      .then(({ data: { data } }) => {
        nextLocationIndex.current =
          nextLocationIndex.current < locations.length - 1
            ? nextLocationIndex.current + 1
            : null;

        let nextPage = page;

        if (data.length) {
          nextPage += 1;

          const windowStart =
            shortcuts?.settings?.booking_window_start &&
            parseISO(shortcuts.settings.booking_window_start).getTime();
          const windowEnd =
            shortcuts?.settings?.booking_window_end &&
            parseISO(shortcuts.settings.booking_window_end).getTime();

          const slots = data.filter(
            (entry) =>
              !(
                windowStart &&
                parseISO(entry.attributes.start).getTime() < windowStart
              ) &&
              !(
                windowEnd &&
                parseISO(entry.attributes.end).getTime() > windowEnd
              ),
          );

          dispatch({
            type: ActionTypes.AddSlots,
            slots: {
              [locationId]: slots?.map(({ attributes }) =>
                Slots.formatSlot(attributes),
              ),
            },
            loading: nextPage <= LOCATION_LIMIT,
          });
        } else {
          dispatch({
            type: ActionTypes.SetLoading,
            loading: nextPage <= LOCATION_LIMIT,
          });
        }

        if (nextPage <= LOCATION_LIMIT) {
          fetchSlots({ page: nextPage });
        }
      });
  };

  const handleSelectTime = ({ currentTarget: { dataset } }) => {
    const id = dataset.locations.split(',')[0];
    const location = locations.find((location) => location.id === id);

    if (location) {
      setSelections({ location });
    }

    selectTime({ currentTarget: { dataset } });
  };

  const showOtherLocations =
    window.location.pathname !== '/reschedule' &&
    location &&
    (settings.preferred_location || !Shortcuts.exists(SHORTCUTS.LOCATION)) &&
    (!specificUser || user);

  useEffect(() => {
    if (showOtherLocations) {
      fetchLocations();
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userCategory]);

  useLayoutEffect(() => {
    if (showOtherLocations && locations?.length) {
      nextLocationIndex.current = 0;
      dispatch({ type: ActionTypes.ClearSlots });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, selected]);

  useEffect(() => {
    if (loading && !googleUser?.refreshing) {
      fetchSlots({});
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleUser?.refreshing]);

  if (!showOtherLocations || !locations) {
    return null;
  }

  const noAvailableTimes =
    locations?.length &&
    nextLocationIndex.current === null &&
    Object.keys(slots).length === 0;

  return (
    <div
      className={classNames(
        classes.root,
        locationHasSlots ? {} : classes.margin,
      )}
    >
      {Object.keys(slots).length ? (
        <div
          className={classNames(
            classes.title,
            mode === MOBILE && classes.mobilePadding,
          )}
        >
          <Typography component="h3" variant="h6">
            <FormattedMessage id="OtherLocationsTimeChunks.available_times_title" />
          </Typography>
        </div>
      ) : null}
      {locations.map((location) =>
        slots[location.id]?.length ? (
          <TimeChunk
            bordered={bordered}
            different={different}
            handleClickTime={handleSelectTime}
            id="location"
            key={location.name}
            label={location.name}
            slots={slots[location.id]}
            specificUser={specificUser}
            subtitle={location.formattedAddress}
          />
        ) : null,
      )}
      {loading ? (
        <div className={mode === MOBILE ? classes.mobilePadding : {}}>
          <CircularProgress centered={false} size="2.25rem" />
        </div>
      ) : noAvailableTimes ? (
        <div className={mode === MOBILE ? classes.mobilePadding : {}}>
          <Typography component="h3" variant="caption1">
            <FormattedMessage id="OtherLocationsTimeChunks.no_available_times" />
          </Typography>
        </div>
      ) : nextLocationIndex.current === null ? null : (
        <div
          className={classNames(
            mode === MOBILE && classes.mobilePadding,
            mode === MOBILE &&
              Object.keys(slots).length &&
              classes.mobileViewMoreButton,
          )}
        >
          <Button
            classes={{ override: classes.button }}
            fullWidth={false}
            id="other-locations-time-chunks-view-more-button"
            onClick={fetchSlots}
            textVariant="subtitle"
            variant="anchor"
          >
            <FormattedMessage
              id={
                Object.keys(slots).length
                  ? 'OtherLocationsTimeChunks.view_more'
                  : 'OtherLocationsTimeChunks.view_available_times'
              }
            />
          </Button>
        </div>
      )}
    </div>
  );
};

OtherLocationsTimeChunks.propTypes = {
  bordered: PropTypes.bool,
  different: PropTypes.bool,
  locationHasSlots: PropTypes.bool.isRequired,
  selected: SpacetimeShape.isRequired,
  selectTime: PropTypes.func.isRequired,
  specificUser: PropTypes.bool,
};

OtherLocationsTimeChunks.defaultProps = {
  bordered: false,
  different: false,
  specificUser: false,
};

export default OtherLocationsTimeChunks;
