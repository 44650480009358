import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  body: {
    flex: '0 1 auto',
    overflowY: 'auto',
    maxHeight: '60vh',
    minHeight: '3rem',
    padding: '1.25rem 1.25rem 1.25rem',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1.25rem',
    flexShrink: '0',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    padding: '1.25rem',
  },
  mobileFooter: {
    flexDirection: 'column-reverse',
  },
}));

const ModalContent = ({ buttons, children, header, testId }) => {
  const mode = useContext(ViewModeContext);
  const classes = useStyles({ theme: useTheme() });

  return (
    <div className={classes.root}>
      {header ? <header className={classes.header}>{header}</header> : null}
      <section
        className={classNames(
          classes.body,
          classes.overrides && classes.overrides.body,
        )}
        data-testid={testId}
      >
        {children}
      </section>
      {buttons ? (
        <footer
          className={classNames(
            classes.footer,
            mode === MOBILE && classes.mobileFooter,
          )}
        >
          {buttons}
        </footer>
      ) : null}
    </div>
  );
};

ModalContent.propTypes = {
  buttons: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  header: PropTypes.element,
  testId: PropTypes.string,
};

ModalContent.defaultProps = {
  testId: null,
};

export default ModalContent;
