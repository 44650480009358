export default {
    "AdditionalUsers.four_plus": "{first} , {second} y {number} más",
    "AdditionalUsers.plus_more": "+ {number} más",
    "AdditionalUsers.three": "{first} , {second} y {third}",
    "AdditionalUsers.two": "{first} y {second}",
    "AppointmentCancelled.email_message": "Le enviamos un correo electrónico confirmando su cancelación.",
    "AppointmentCancelled.this_title": "Esta cita ha sido cancelada",
    "AppointmentCancelled.your_title": "Su cita ha sido cancelada",
    "AppointmentDetails.appointment_details": "Detalles de la cita",
    "AppointmentDetails.appointment_duration": "{start} - {end} ({timezone})",
    "AppointmentDetails.appointment_duration_without_end": "{start} ({timezone})",
    "AppointmentDetails.attendee": "Asistente",
    "AppointmentDetails.attendees": "Asistentes",
    "AppointmentDetails.confirmation_number": "Número de confirmación",
    "AppointmentDetails.date_time": "Fecha y hora",
    "AppointmentDetails.join_by_phone": "Únase por teléfono: <a> {number} <\/a>",
    "AppointmentDetails.join_call": "Unirse a la llamada",
    "AppointmentDetails.join_the_call": "Únete con el enlace provisto",
    "AppointmentDetails.join_with_this_link": "Únase usando este enlace",
    "AppointmentDetails.location": "Ubicación",
    "AppointmentDetails.phone_call": "Llamada telefónica",
    "AppointmentDetails.phone_number_ending_in": "Número de teléfono que termina en {number}",
    "AppointmentDetails.pin": "PIN: {pin}",
    "AppointmentDetails.service": "Servicio",
    "AppointmentDetails.user": "Personal",
    "AppointmentDetails.video_call": "Videollamada",
    "AppointmentDetails.we_will_call": "Le llamaremos al número que nos proporcione.",
    "AppointmentDetails.we_will_call_you": "Lo llamaremos al número que proporcionó.",
    "AppointmentDetails.we_will_send_video_info": "Le enviaremos información sobre cómo unirse a la reunión.",
    "AppointmentSummary.cancel": "Cancelar",
    "AppointmentSummary.default_error_title": "Ocurrió un error",
    "AppointmentSummary.location_button": "Ver ubicaciones",
    "AppointmentSummary.location_unavailable_description": "Esta ubicación ya no está disponible. Deberá seleccionar otra ubicación antes de poder reservar una cita.",
    "AppointmentSummary.location_unavailable_title": "Ubicación ya no disponible",
    "AppointmentSummary.modal_title": "Título",
    "AppointmentSummary.service_button": "Ver servicios",
    "AppointmentSummary.service_unavailable_description": "Este servicio ya no está disponible. Por favor, vea si otros servicios satisfacen sus necesidades o contáctenos para asistencia al {phone}.",
    "AppointmentSummary.service_unavailable_title": "Servicio ya no disponible",
    "AppointmentSummary.times_button": "Encuentra un hora",
    "AppointmentSummary.times_unavailable_description": "{time} ya no está disponible en {date}. Deberá seleccionar otro horario antes de poder reservar una cita.",
    "AppointmentSummary.times_unavailable_title": "Tiempo ya no disponible",
    "AppointmentSummaryExpansionPanel.title": "Resumen de citas",
    "AttendeeListItem.delete": "Eliminar asistente adicional",
    "AttendeeListItem.deleted": "{name} Eliminado",
    "AttendeeListItem.edit": "Actualizar asistente adicional",
    "BackButton.back": "Regresar",
    "BackButton.back_results": "Regresar a resultados",
    "BookingValidationErrorModal.default_error_title": "Ocurrió un error",
    "BookingValidationErrorModal.location_button": "Ver ubicaciones",
    "BookingValidationErrorModal.location_unavailable_description": "Esta ubicación ya no está disponible. Deberá seleccionar otra ubicación antes de poder reservar una cita.",
    "BookingValidationErrorModal.location_unavailable_title": "Ubicación ya no disponible",
    "BookingValidationErrorModal.modal_title": "Título",
    "BookingValidationErrorModal.service_button": "Ver servicios",
    "BookingValidationErrorModal.service_unavailable_description": "Este servicio ya no está disponible. Por favor, vea si otros servicios satisfacen sus necesidades o contáctenos para asistencia al {phone}.",
    "BookingValidationErrorModal.service_unavailable_title": "Servicio ya no disponible",
    "BookingValidationErrorModal.times_button": "Encuentra un hora",
    "BookingValidationErrorModal.times_unavailable_description": "{time} ya no está disponible en {date}. Deberá seleccionar otro momento antes de poder reservar una cita.",
    "BookingValidationErrorModal.times_unavailable_title": "Tiempo ya no disponible",
    "CancelModal.additional_details": "Detalles Adicionales",
    "CancelModal.appointment_date": "{date} en {time}",
    "CancelModal.cancel_appointment": "Cancelar Cita",
    "CancelModal.keep": "Mantener",
    "CancelModal.message": "La cancelación de la cita programada en {date} no se puede deshacer.",
    "CancelModal.title": "Cancelar Cita",
    "CancelModal.why_cancel_appointment": "¿Por qué se cancela esta cita?",
    "ChangesToYourAppointment.cancellation_no_longer_available": "Esta cita ya no se puede cancelar.",
    "ChangesToYourAppointment.cancellation_policy_header": "Política de cancelación",
    "ChangesToYourAppointment.cannot_cancel": "Esta cita no puede ser cancelada.",
    "ChangesToYourAppointment.cannot_reschedule": "Esta cita no puede ser reprogramada.",
    "ChangesToYourAppointment.loading": "Cargando...",
    "ChangesToYourAppointment.meeting_method_subtitle": "Si desea cambiar la forma en que le gustaría asistir a su cita, actualice el método de reunión.",
    "ChangesToYourAppointment.meeting_method_subtitle_limited": "Si desea cambiar la forma en que le gustaría asistir a su cita, puede hacerlo hasta {duration} antes de la hora programada.",
    "ChangesToYourAppointment.meeting_method_subtitle_locked": "El método de reunión no se puede cambiar",
    "ChangesToYourAppointment.no_meeting_method_options": "No hay otras opciones disponibles",
    "ChangesToYourAppointment.reschedule_appointment": "Reagendar Cita",
    "ChangesToYourAppointment.reschedule_header": "Cambiar la fecha\/hora de",
    "ChangesToYourAppointment.reschedule_no_longer_available": "Esta cita ya no se puede reprogramar.",
    "ChangesToYourAppointment.reschedule_policy_details": "Si necesita reprogramar su cita, puede hacerlo hasta {duration} antes de la hora programada.",
    "ChangesToYourAppointment.reschedule_policy_details_no_limit": "Si necesita reprogramar su cita, puede hacerlo antes de la hora programada.",
    "ChangesToYourAppointment.title": "Cambios a su cita",
    "ChangesToYourAppointment.update_meeting_method": "Actualizar el método de reunión",
    "ChangesToYourAppointment.update_meeting_method_not_available": "Este método de reunión de citas ya no se puede cambiar.",
    "CheckIn.check_in": "Registrarse",
    "CheckIn.check_in.aria_label": "Ingresa a tu cita",
    "CheckIn.confirmation.subtitle": "Pronto estaremos contigo.",
    "CheckIn.confirmation.subtitle_notifiable": "¡Pronto estaremos contigo. Te avisaremos cuando sea tu turno.",
    "CheckIn.confirmation.title": "¡Estás registrado!",
    "CheckIn.email": "Dirección de correo electrónico",
    "CheckIn.fetch_failed": "Lo sentimos, pero ocurrió un error al buscar su cita. Actualice la página y vuelva a intentarlo.",
    "CheckIn.join_line": "ÚNETE A LA LÍNEA",
    "CheckIn.join_line.aria_label": "Haga clic aquí para unirse a la línea para ser visto en lugar de reservar una cita",
    "CheckIn.join_line.title": "¿No tienes una cita?",
    "CheckIn.landing_page.title": "Consulta tu cita",
    "CheckIn.next": "Próximo",
    "CheckIn.next.aria_label": "Consulta tu cita",
    "CheckIn.not_found.email": "Lo sentimos, pero no pudimos encontrar una próxima cita que coincida con la dirección de correo electrónico que ingresó.",
    "CheckIn.not_found.phone": "Lo sentimos, pero no pudimos encontrar una próxima cita que coincida con el número de teléfono que ingresó.",
    "CheckIn.phone": "Número de teléfono",
    "CheckIn.preference": "Usando su {preference} para buscar su cita",
    "CheckIn.subtitle": "Use su dirección de correo electrónico o número de teléfono para buscar su cita",
    "CheckIn.subtitle_email_only": "Use su dirección de correo electrónico para buscar su cita",
    "CheckIn.summary.fetch_failed": "Lo sentimos, pero ocurrió un error al registrar su cita. Actualice la página y vuelva a intentarlo.",
    "CheckIn.summary.sms_updates": "Reciba actualizaciones por mensaje de texto",
    "CheckIn.summary.sms_updates.dialog": "¿Recibir actualizaciones por mensaje de texto?",
    "CheckIn.summary.sms_updates.dialog.accept": "Sí, envíame un mensaje de texto con las actualizaciones",
    "CheckIn.summary.sms_updates.dialog.deny": "No, regístrese sin recibir actualizaciones",
    "CheckIn.summary.sms_updates.message": "Te enviaremos actualizaciones sobre cuándo es tu turno de {phone}",
    "CheckIn.summary.subtitle": "Confirme los detalles de su cita a continuación y regístrese",
    "CheckIn.summary.title": "Confirmar y registrarse",
    "CheckIn.title": "Consulta tu cita",
    "CircularProgress.loading": "Cargando",
    "Common.appointment_details": "Detalles de la cita",
    "CookieNotice.accept": "Acepto",
    "CookieNotice.closed": "Descargo de responsabilidad de cookies cerrado.",
    "CookieNotice.ignore": "Ignorar",
    "CookieNotice.learn_more": "Aprende más",
    "CookieNotice.message": "Utilizamos cookies para garantizar que obtenga la mejor experiencia en nuestro sitio web.",
    "CookieNotice.title": "Descargo de responsabilidad sobre cookies",
    "CountrySelectInput.label": "País",
    "CountrySelectInput.most_popular": "Más popular",
    "CountryTimezoneModal.description": "Todos los horarios se mostrarán de acuerdo con la zona horaria seleccionada a continuación.",
    "CountryTimezoneModal.title": "Selecciona la zona horaria",
    "DateTime.locale_badge_label": "{name} habla {language}",
    "DateTime.locale_badge_tooltip": "Habla {language}",
    "DateTime.mobile_header": "¿Cuándo le gustaría tener su cita?",
    "DateTime.no_language_preference": " (sin {locale})",
    "DateTime.no_times_available_on_date": "No hay horarios disponibles",
    "DateTime.select_time": "Seleccionar hora",
    "DateTime.select_user": "Seleccione un miembro del personal",
    "DateTime.showing_times_for": "Mostrando tiempos para",
    "DateTime.times_available_for_people_speaking": "Tiempos disponibles para {locale} en {date}",
    "DateTime.times_available_on_date": "Tiempos disponibles el {date}",
    "DateTime.timezone_header": "Los tiempos se muestran en {timezone}",
    "DateTime.user_mobile_header": "¿Cuándo le gustaría reunirse con {name} ?",
    "DateTime.user_schedule": "Programe una hora con {name}",
    "DateTime.who_speaks": " que hable {locale}",
    "Dates.all_day_date": "{date}, todo el día",
    "Dates.all_day_today": "Hoy, todo el día",
    "Dates.plural.day": "{count, plural, one {día} other {días}}",
    "Dates.plural.month": "{count, plural, one {mes} other {meses}}",
    "Dates.plural.week": "{count, plural, one {semana} other {semanas}}",
    "Dates.plural.year": "{count, plural, one { año } other { años }}",
    "Dates.range_today": "Hoy, {start} - {end}",
    "Dates.range_today_with_timezone": "Hoy, {start} - {end} {timezone}",
    "Dates.today_with_timezone": "Hoy, {date} {timezone}",
    "DesktopUsers.locale_badge_tooltip": "Habla {language}",
    "Details.form_header": "Introduzca su información",
    "DetailsForm.add": "Añadir",
    "DetailsForm.add_attendee": "+ Añadir asistente adicional",
    "DetailsForm.add_attendee_title": "Agregar asistente",
    "DetailsForm.additional_attendees": "Asistentes adicionales",
    "DetailsForm.additional_attendees_limit": "(máx {limit} )",
    "DetailsForm.address": "Dirección",
    "DetailsForm.agree_tos": "Al reservar una cita, usted acepta los {terms} y {privacy} de Coconut Software.",
    "DetailsForm.agree_tos_with_privacy": "Al reservar una cita, usted acepta los {terms} y {privacy} y {vendor} Coconut Software {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_tos": "Al reservar una cita, usted acepta los {terms} y {privacy} y {vendor} Coconut Software {terms} {vendorTerms} .",
    "DetailsForm.agree_tos_with_vendor": "Al reservar una cita, usted acepta los {terms} y {privacy} y {vendor} Coconut Software {terms} {vendorTerms} y {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_vendor_full_privacy": "Al reservar una cita que está de acuerdo con {vendor} 's {vendorTerms} y {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_vendor_full_privacy_policy": "Al reservar una cita que está de acuerdo con {vendor} 's {vendorPrivacy} .",
    "DetailsForm.agree_tos_with_vendor_full_privacy_tos": "Al reservar una cita que está de acuerdo con {vendor} 's {vendorTerms} .",
    "DetailsForm.appointment_policy_action": "reservar una cita",
    "DetailsForm.cell_phone": "Número de teléfono móvil",
    "DetailsForm.cell_phone_context": "Lo llamaremos a este número",
    "DetailsForm.city": "Ciudad",
    "DetailsForm.coconut_privacy_url": "https:\/\/www.coconutsoftware.com\/privacy-policy\/",
    "DetailsForm.coconut_terms_url": "https:\/\/www.coconutsoftware.com\/terms-of-use\/",
    "DetailsForm.country": "País",
    "DetailsForm.duplicate_attendee": "Ya existe un asistente con este nombre y correo electrónico",
    "DetailsForm.edit": "Salvar",
    "DetailsForm.edit_attendee_title": "Editar asistente",
    "DetailsForm.email": "Correo electrónico",
    "DetailsForm.explicit_compliance_agreement_label": "acepto",
    "DetailsForm.first_name": "Nombre",
    "DetailsForm.home_phone": "Número de teléfono de casa",
    "DetailsForm.last_name": "Apellido",
    "DetailsForm.notes": "Detalles de la cita",
    "DetailsForm.postal_code": "Código postal",
    "DetailsForm.privacy": "aviso de privacidad",
    "DetailsForm.province": "Provincia",
    "DetailsForm.receive_sms": "Recibir notificaciones de citas por mensaje de texto",
    "DetailsForm.receive_sms_required": "Utilizaremos esta información para enviarle actualizaciones importantes sobre su cita",
    "DetailsForm.select_country": "Por favor seleccione un país",
    "DetailsForm.select_province": "Por favor seleccione una provincia",
    "DetailsForm.select_state": "Por favor seleccione un estado",
    "DetailsForm.sms_error_title": "Vuelve a participar para recibir mensajes de texto.",
    "DetailsForm.state": "Estado",
    "DetailsForm.submit": "Continuar",
    "DetailsForm.terms_of_use": "términos de uso",
    "DetailsForm.walkin_policy_action": "uniéndose a la línea",
    "DetailsForm.work_phone": "Número de teléfono del trabajo",
    "Dialog.clear": "Borrar",
    "Dialog.confirm": "Confirmar",
    "Dropzone.delete": "Borrar",
    "Dropzone.instructions": "Arrastra los archivos aquí o <span> navega para subirlos <\/span>",
    "Duration.days": "{duration, plural, one {# día } other {# días }}",
    "Duration.hours": "{duration, plural, one {# hora } other {# horas }}",
    "Duration.minutes": "{duration, plural, one {# minuto} other {# minutos}}",
    "Duration.weeks": "{duration, plural, one {# semana } other {# semanas }}",
    "EmptyState.contact_vendor": "Por favor intente nuevamente o contáctenos",
    "FastRoute.book_anyway": "Reserve una cita en su lugar",
    "FastRoute.skip_the_wait": "Sáltate la espera",
    "FastRoute.title": "No se necesita cita",
    "Feedback.already_submitted": "Comentarios ya enviados, ¡gracias!",
    "Feedback.appointment_review_subtitle": "Por favor díganos cómo se sintió acerca de su cita.",
    "Feedback.appointment_review_title": "Comentarios sobre la cita",
    "Feedback.close": "Cerrar sección de comentarios",
    "Feedback.error": "Proporcione una calificación antes de enviar",
    "Feedback.experience": "Tu experiencia",
    "Feedback.experience_error_message": "El comentario es un campo obligatorio. Asegúrese de que la longitud sea superior a 144 caracteres.",
    "Feedback.experience_required": "Tu Experiencia (Requerido)",
    "Feedback.google_description": "Realmente valoramos sus comentarios y creemos que nuestros otros clientes se beneficiarán de ellos. Si tiene unos momentos para informarles sobre su experiencia con nosotros a través de una revisión de Google, haga clic en el botón a continuación y lo redirigiremos.",
    "Feedback.google_submit": "Enviar revisión de Google",
    "Feedback.neutral": "Neutral",
    "Feedback.new_title": "Realimentación",
    "Feedback.rating": "Calificación (requerido)",
    "Feedback.response_prompt": "Cuéntanos cómo tu experiencia podría haber sido mejor",
    "Feedback.send": "Enviar comentarios",
    "Feedback.sending": "Envío de comentarios",
    "Feedback.staff_review_subtitle": "Díganos cómo le fue hoy a nuestro miembro del personal.",
    "Feedback.staff_review_title": "Comentarios del personal",
    "Feedback.subtitle": "Sus comentarios son anónimos. Usaremos esta información para mejorar la experiencia de reserva.",
    "Feedback.success": "Comentarios enviados con éxito, ¡gracias!",
    "Feedback.thank_you": "¡Gracias por tus comentarios!",
    "Feedback.title": "¿Cómo fue tu experiencia al reservar esta cita?",
    "Feedback.very_dissatisfied": "Muy insatisfecho",
    "Feedback.very_satisfied": "Muy Satisfecho",
    "FetchSlots.error": "Algo salió mal, inténtalo de nuevo más tarde",
    "FindAvailableDate.earliest_available": "Más temprano disponible",
    "FindAvailableDate.finding_first_time": "Encontrar el primer tiempo disponible",
    "FindAvailableDate.finding_next_time": "Encontrar la próxima hora disponible",
    "FindAvailableDate.finding_time": "Encontrar un tiempo disponible",
    "FindAvailableDate.first_available_date": "Lo antes posible",
    "FindAvailableDate.next_available": "Siguiente disponible",
    "FindAvailableDate.next_available_date": "Próxima fecha disponible",
    "FindAvailableDate.tooltip_error": "Error al obtener la fecha.",
    "FindAvailableDate.tooltip_fetching": "Obteniendo fecha...",
    "Form.field_error.cell_phone_characters": "Por favor, introduzca un número de teléfono móvil válido.",
    "Form.field_error.cell_phone_length": "Ingrese un número de teléfono celular válido de entre 9 y 20 caracteres.",
    "Form.field_error.email_structure": "Por favor, introduce una dirección de correo electrónico válida.",
    "Form.field_error_max_length": "Este campo no puede exceder {max} caracteres.",
    "Form.field_error_min_length": "Este campo no puede tener menos de {min} caracteres.",
    "Form.field_required": "Este campo es obligatorio.",
    "Form.optional": "(opcionales)",
    "GenericError.subtitle": "La página que estás buscando no se puede encontrar",
    "GenericError.title": "¡Huy! Algo salió mal",
    "Input.additional_input": "Mensaje adicional",
    "Invitations.verification_failed": "La dirección de correo electrónico que proporcionó no coincide con nuestros archivos.",
    "Label.phone_number": "Número de teléfono",
    "LandingChoices.book_appointment_subtitle": "Seleccione una fecha y hora específicas para ver a alguien",
    "LandingChoices.book_appointment_title": "Reservar una cita",
    "LandingChoices.callback_subtitle": "Ver a alguien hoy tan pronto como sea posible",
    "LandingChoices.callback_title": "Únete a la línea",
    "LandingChoices.check_in_subtitle": "Si está aquí para una cita reservada",
    "LandingChoices.check_in_title": "Registrarse",
    "LandingChoices.choose_another_location_subtitle": "Haga clic aquí para buscar otra ubicación",
    "LandingChoices.prefill_title": "¡Bienvenido {name} ! ¿Qué le gustaría hacer {space} ?",
    "LandingChoices.prefill_title.service": "¡Bienvenido {name} ! ¿Cómo le gustaría reservar su cita de {service}",
    "LandingChoices.private_location": "Esta ubicación es privada",
    "LandingChoices.title": "¿Qué le gustaría hacer?",
    "LandingChoices.title.service": "¿Cómo le gustaría reservar su cita de {service}",
    "LandingChoices.virtual_location": "Locación virtual",
    "LandingChoices.welcome_to": "Bienvenido a",
    "Languages.chinese": "Chino",
    "Languages.english": "inglés",
    "Languages.french": "francés",
    "Languages.korean": "coreano",
    "Languages.language": "Idioma",
    "Languages.polish": "Polaco",
    "Languages.portuguese": "portugués",
    "Languages.russian": "ruso",
    "Languages.spanish": "español",
    "LobbyBanner.button": "Únete a la línea",
    "LobbyBanner.button_description": "Haga clic aquí para unirse a la línea para ser visto en lugar de reservar una cita",
    "LobbyBanner.phone_button": "Solicitar una devolución de llamada",
    "LobbyBanner.phone_button_description": "Haga clic aquí para reservar una devolución de llamada telefónica en lugar de reservar una cita",
    "LobbyBanner.title": "¡No es necesario hacer cita!",
    "LobbyBanner.wait_time": "Tiempo de espera estimado: {time} minutos",
    "Location.previously_booked": "Reservado previamente",
    "Location.search_area": "Buscar en esta zona",
    "Location.select_location": "Selecciona una ubicación",
    "Location.select_location_prefill": "{name} , seleccione una ubicación",
    "LocationDetails.continue": "Continuar",
    "LocationDetails.location_details": "Detalles de ubicación",
    "LocationDetails.see_all_locations": "Ver todas las ubicaciones",
    "LocationDetails.select_location": "Seleccione esta ubicación",
    "LocationDirectionsButton.text": "Direcciones",
    "LocationHours.closed": "Cerrado",
    "LocationHours.title": "Horas de apertura",
    "LocationIdentifier.empty_error": "Ingrese el número de ubicación",
    "LocationIdentifier.help_text": "Comience ingresando el número de ubicación que ve en el letrero",
    "LocationIdentifier.location_number": "Número de ubicación",
    "LocationIdentifier.not_found_error": "Esto no coincide con ninguna de nuestras ubicaciones",
    "LocationIdentifier.title": "¡Bienvenido!",
    "LocationList.empty_state_message": "No se han encontrado resultados",
    "LocationList.empty_state_secondary": "Intente ampliar su búsqueda",
    "LocationList.finding_locations": "Estamos encontrando ubicaciones cerca de ti",
    "LocationList.near_me": "Ubicaciones cercanas a mi",
    "LocationList.no_locations": "No se encontraron ubicaciones",
    "LocationList.title": "Selecciona una ubicación",
    "LocationList.toggle_list": "Mostrar lista",
    "LocationList.toggle_map": "Mostrar mapa",
    "LocationPhoneCallButton.text": "Llamar {number}",
    "LocationStepTitle.preferred_location": "¿Cuál es tu ubicación preferida?",
    "LocationStepTitle.preferred_location_helper": "Lo contactaremos con el personal de la ubicación que elija.",
    "LoginWithGoogle.showing_times_that_match": "Mostrando horarios que coinciden con su disponibilidad",
    "LoginWithGoogle.sign_in": "Inicia sesión con Google",
    "LoginWithGoogle.sign_in_details": "para mostrar horarios que coincidan con su disponibilidad",
    "Manage.appointment_details": "Detalles de la cita",
    "Manage.book_another": "Reservar otra cita",
    "Manage.can_never_cancel": "La gestión en línea no está disponible. Por favor contáctenos para hacer cambios.",
    "Manage.cancel": "Cancelar Cita",
    "Manage.cancellation_policy": "Política de cancelación",
    "Manage.cancellation_policy_details": "Si no puede asistir a su cita, cancele hasta {duration} antes de la hora programada.",
    "Manage.cancellation_policy_details_no_limit": "Si no puede asistir a su cita, cancele antes de la hora programada.",
    "Manage.cancelled": "Cita cancelada",
    "Manage.cancelled_successfully": "La cita ha sido cancelada",
    "Manage.cannot_cancel_anymore": "La administración en línea no está disponible en este momento. Por favor contáctenos para hacer cambios.",
    "Manage.confirmation": "Confirmación",
    "Manage.confirmation_email": "Le enviamos un correo electrónico con los detalles de su cita, junto con cualquier información adicional relacionada con su cita.",
    "Manage.details": "Detalles",
    "Manage.in_the_past": "La cita está en el pasado",
    "Manage.reschedule": "Cambiar la fecha\/hora de",
    "Manage.restart": "Reiniciar desde la página de inicio",
    "Manage.successfully_booked": "Cita reservada con éxito",
    "Manage.updated_successfully": "La cita ha sido reprogramada",
    "ManageAppointmentDetails.details_below": "Los detalles de su cita están abajo. Le hemos enviado una copia por correo electrónico.",
    "ManageAppointmentDetails.done_title": "¡Hecho!",
    "ManageAppointmentDetails.successfully_booked": "Su cita está reservada.",
    "ManageAppointmentDetails.successfully_rescheduled": "Su cita ha sido reprogramada.",
    "ManageAppointmentDetails.your_appointment": "Su cita",
    "MeetingMethodForm.appointment_date": "{date} a las {time}",
    "MeetingMethodForm.keep": "Guardar",
    "MeetingMethodForm.message.in_person": "Actualice el método de reunión para la cita programada para el {date} para reunirse en persona.",
    "MeetingMethodForm.message.off_site": "Actualice el método de reunión para la cita programada para el {date} para reunirse donde desee.",
    "MeetingMethodForm.message.phone_call": "Actualice el método de reunión para la cita programada para el {date} como una llamada telefónica.",
    "MeetingMethodForm.message.video_call": "Actualiza el método de reunión para la cita programada para el {date} como videollamada.",
    "MeetingMethodForm.new_method_label": "¿Cómo asistirá a la cita?",
    "MeetingMethodForm.title": "Actualizar el método de reunión",
    "MeetingMethodForm.update_method": "Actualizar el método de reunión",
    "MeetingMethodInfoBanner.only_phone_available": "Esta reunión solo está disponible por llamada telefónica",
    "MeetingMethodInfoBanner.only_video_available": "Esta reunión solo está disponible por videollamada",
    "MeetingMethods.at_location_primary": "En persona",
    "MeetingMethods.at_location_secondary": "Elija una de nuestras ubicaciones",
    "MeetingMethods.header": "¿Cómo asistirá a la cita?",
    "MeetingMethods.off_site_primary": "Fuera del sitio",
    "MeetingMethods.off_site_secondary": "Nos reuniremos contigo",
    "MeetingMethods.phone_call_primary": "Teléfono",
    "MeetingMethods.phone_call_secondary": "Te llamaremos",
    "MeetingMethods.video_call_primary": "Vídeo",
    "MeetingMethods.video_call_secondary": "Te enviaremos un enlace",
    "MonthlyDatePicker.next_month": "Próximo mes",
    "MonthlyDatePicker.previous_month": "Mes anterior",
    "Navigation.aria_label.back": "Volver al paso anterior",
    "Navigation.aria_label.call": "Ubicación de la llamada",
    "Navigation.aria_label.card": "Seleccione {navItem}",
    "Navigation.aria_label.date": "Seleccionar {date}",
    "Navigation.aria_label.directions": "Ver direcciones",
    "Navigation.aria_label.language": "{language} personal que habla",
    "Navigation.aria_label.language_specific": "un personal específico",
    "NoLocationPreferenceButton.skip": "Omitir",
    "NoLocationPreferenceButton.title": "No tengo preferencia",
    "NoTimesAvailable.no_times_in_month": "No hay horarios en {month}",
    "NoTimesAvailable.no_times_in_week": "No hay veces esta semana",
    "NoTimesAvailable.view_next_month": "Ver el próximo mes",
    "NoTimesAvailable.view_next_week": "Ver la próxima semana",
    "NotAvailable.subtitle": "Actualmente no estamos tomando citas",
    "NotAvailable.title": "No disponible",
    "OtherLocationsTimeChunks.available_times_title": "Horarios disponibles en otras ubicaciones",
    "OtherLocationsTimeChunks.no_available_times": "No hay horarios disponibles en otras ubicaciones",
    "OtherLocationsTimeChunks.view_available_times": "Ver horarios disponibles en otras ubicaciones",
    "OtherLocationsTimeChunks.view_more": "Ver más",
    "Pages.country": "País",
    "Pages.staff_preference": "Preferencia de personal",
    "Pages.supported_language_preference": "Idiomas",
    "Pages.timezones": "Zonas horarias",
    "PositionErrorAlert.denied": "No pudimos obtener su ubicación porque no tenemos su permiso. Verifique la configuración de su navegador para este sitio.",
    "PositionErrorAlert.retry": "Rever",
    "PositionErrorAlert.unable": "No pudimos obtener tu ubicación.",
    "RadioButton": "Boton de radio",
    "Reschedule.appointment_details": "Detalles de la cita",
    "Reschedule.confirm_time": "Confirmar",
    "Reschedule.error": "La cita no puede ser reprogramada",
    "Reschedule.label": "Confirmación",
    "Reschedule.reschedule": "Cambiar la fecha\/hora de",
    "Security.continue": "Continuar",
    "Security.email_verification": "verificacion de email",
    "Security.please_verify": "Verifique su dirección de correo electrónico antes de reservar su cita.",
    "SelectInput.default": "Seleccionar",
    "Service.all_categories_title": "Todas las categorías",
    "Service.category_title": "Categorías",
    "Service.five_minutes": "< 5 minutos",
    "Service.hours": "{hours, plural, one {# hora } other {# horas }}",
    "Service.minutes": "{minutes, plural, one {# minuto } other {# minutos }}",
    "Service.mobile_header": "¿Con qué área necesita ayuda?",
    "Service.no_services": "No se encontraron servicios",
    "Service.read_less": "Menos",
    "Service.read_more": "Más",
    "Service.select_title": "Seleccione un servicio",
    "Service.select_title_prefill": "{name} , {break} seleccione un servicio",
    "Service.service_count": "{number, plural, one {# servicio} other {# servicios}}",
    "Service.uncategorized_title": "Otro",
    "ServiceHeader.group": "Grupo",
    "ServiceInstructions.title": "Instrucciones",
    "ShortcutExpired.message": "Este enlace ha caducado",
    "Sidebar.footer": "Desarrollado por",
    "Sidebar.title": "Reservar una cita",
    "Sidebar.title_check_in": "Registrarse",
    "Sidebar.title_join_the_line": "Únete a la línea",
    "SpecificUserLanguagePreferences.title": "preferencia de idioma",
    "StepCounter.location": "{location}",
    "StepCounter.location_address": "{address}",
    "StepCounter.location_name": "{name}",
    "StepCounter.title": "Paso {current} de {end}",
    "Steps.check_in": "Registrarse",
    "Steps.check_in_description": "<p> Regístrese para su cita. <\/p> <p> Le notificaremos cuando un miembro del personal esté listo para atenderlo. <\/p>",
    "Steps.date_time": "Fecha y hora",
    "Steps.date_time_no_wait_times": "Tan pronto como un miembro del personal esté listo para servirle",
    "Steps.date_time_with_wait_times": "Tiempo de espera estimado: {time} minutos",
    "Steps.date_time_with_zero_wait_times": "No hay tiempo de espera en este momento",
    "Steps.details": "Detalles",
    "Steps.details_description": "<p> Agrégate para que te vean hoy. <\/p> <p> Le notificaremos que regrese cuando un miembro del personal esté listo para atenderlo. <\/p>",
    "Steps.location": "Ubicación",
    "Steps.meeting_details": "Detalles de la reunión",
    "Steps.meeting_methods": "Método de reunión",
    "Steps.no_staff_warning": "Al unirse a la línea, será atendido por el primer miembro del personal disponible. Este puede no ser {name} , a quien habías seleccionado previamente",
    "Steps.service": "Servicio",
    "Steps.services": "Servicios",
    "Steps.staff": "Personal",
    "Steps.staff_preference": "Preferencia de personal",
    "Steps.summary": "Resumen",
    "Steps.your_details": "Tus detalles",
    "Summary.book_appointment": "Reservar una cita",
    "Summary.header": "¡Casi termino! Revisar y reservar",
    "Summary.join_the_line": "Únete a la línea",
    "Summary.please_review": "Por favor revise",
    "SummaryExpansionPanel.title": "Resumen",
    "SupportedLanguagePreferencePicker.anyone": "cualquiera",
    "SupportedLanguagePreferencePicker.anyone_available": "cualquier persona disponible",
    "SupportedLanguagePreferencePicker.assigned_staff": "su personal asignado",
    "SupportedLanguagePreferencePicker.language_preference": "preferencia de idioma",
    "SupportedLanguagePreferencePicker.specific_language": "cualquiera que hable {language}",
    "Svg.alt_text.appointment": "Cita",
    "Svg.alt_text.back": "atrás",
    "Svg.alt_text.back_chevron": "atrás",
    "Svg.alt_text.checkbox_not_selected": "Casilla de verificación no seleccionada",
    "Svg.alt_text.checkbox_selected": "Elemento de casilla de verificación seleccionado",
    "Svg.alt_text.checkmark": "Marca de verificación",
    "Svg.alt_text.clock": "Reloj",
    "Svg.alt_text.coconut_logo": "Logo de la compañía Coconut Software",
    "Svg.alt_text.completed": "Terminado",
    "Svg.alt_text.continue": "Continuar",
    "Svg.alt_text.country_selected": "País seleccionado",
    "Svg.alt_text.down_chevron": "Mostrar más",
    "Svg.alt_text.drop_down_arrow": "Flecha desplegable",
    "Svg.alt_text.edit": "Editar",
    "Svg.alt_text.edit_timezone": "Cambiar zona horaria",
    "Svg.alt_text.expand": "Expandir",
    "Svg.alt_text.expanded": "Expandido",
    "Svg.alt_text.forward_chevron": "Hacia adelante",
    "Svg.alt_text.hide_location_details": "Ocultar los detalles de la ubicación",
    "Svg.alt_text.in_person": "En la ubicación",
    "Svg.alt_text.item_selected": "Elemento seleccionado",
    "Svg.alt_text.language": "Idioma",
    "Svg.alt_text.location_pin": "Localización",
    "Svg.alt_text.near_me": "Cerca de mí",
    "Svg.alt_text.next_month": "Próximo mes",
    "Svg.alt_text.next_week": "La próxima semana",
    "Svg.alt_text.person": "Persona",
    "Svg.alt_text.phone": "Teléfono",
    "Svg.alt_text.previous_month": "Mes anterior",
    "Svg.alt_text.previous_week": "Semana pasada",
    "Svg.alt_text.search": "Buscar",
    "Svg.alt_text.select_category": "Seleccione una categoría",
    "Svg.alt_text.select_language": "Selecciona un idioma",
    "Svg.alt_text.show_location_details": "Mostrar detalles de ubicación",
    "Svg.alt_text.timezone": "Zona horaria",
    "Svg.alt_text.timezone_selected": "Zona horaria seleccionada",
    "Svg.alt_text.up_chevron": "Muestra menos",
    "Svg.alt_text.upload": "Subir",
    "Svg.alt_text.user_selected": "Usuario seleccionado",
    "Svg.alt_text.video": "Vídeo",
    "Svg.alt_text.walk_in": "Icono de servicio sin cita previa",
    "TimeChunk.no_spots": "No quedan franjas horarias",
    "TimeChunk.spots_remaining": "{number, plural, one {# lugar restante} other {# lugares restantes}}",
    "TimeChunk.unlimited_spots": "Spots ilimitados",
    "TimeChunks.afternoon": "Tarde",
    "TimeChunks.evening": "Noche",
    "TimeChunks.morning": "Mañana",
    "TimeChunks.no_available_times": "No hay horarios disponibles",
    "TimeChunks.no_available_times_in_month": "No se encontró disponibilidad este mes.",
    "TimeChunks.no_available_times_in_range": "No se encontró disponibilidad dentro de un período {range} {rangeUnit}.",
    "TimeChunks.no_available_times_in_range_month": "No se encontró disponibilidad dentro de un período {range} mes.",
    "TimeChunks.no_available_times_in_range_week": "No se encontró disponibilidad dentro de un período {range} semana.",
    "TimeChunks.no_available_times_in_week": "No se encontró disponibilidad esta semana.",
    "TimeChunks.no_available_times_on": "No hay horarios disponibles el {date}",
    "TimeChunks.select_another_day": "Seleccione otro día para encontrar los horarios disponibles",
    "TimeChunks.select_another_month": "Prueba otro mes.",
    "TimeChunks.select_another_range": "Pruebe con otro miembro del personal o ubicación.",
    "TimeChunks.select_another_week": "Prueba otra semana.",
    "TimeChunks.select_user": "Seleccione un miembro del personal para ver su disponibilidad",
    "TimezoneSelectInput.label": "Región de zona horaria",
    "TimezoneSelectInput.required": "Se requiere zona horaria.",
    "TimezonesShownIn.label": "Mostrando tiempos en",
    "TooLateToCancelModal.message": "Ya no puede cancelar esta cita en línea.",
    "TooLateToCancelModal.title": "Demasiado tarde para cancelar",
    "Ui.archive": "Archivo",
    "Ui.back": "Regresar",
    "Ui.back_to_previous": "Volver a: {previous}",
    "Ui.cancel": "Cancelar",
    "Ui.clear": "Borrar",
    "Ui.close": "Cerca",
    "Ui.confirm": "Confirmar",
    "Ui.continue": "Continuar",
    "Ui.current_selection": "Selección actual",
    "Ui.details_about_name": "Detalles sobre {name}",
    "Ui.details_about_name_and_address": "Detalles sobre {name}, en {address}",
    "Ui.dismiss": "Descartar",
    "Ui.do_not_continue": "Continuar",
    "Ui.hours_of_operation": "Horas de operación",
    "Ui.near_me": "Cerca de mí",
    "Ui.next": "Próximo",
    "Ui.ok": "De acuerdo",
    "Ui.search": "Buscar",
    "Ui.search.instructions": "Por favor empieza a escribir",
    "Ui.search.no_results_found": "No se han encontrado resultados",
    "Ui.search.plain": "Buscar",
    "Ui.select": "Seleccionar",
    "Ui.select_name": "Seleccionar {name}",
    "Ui.selected": "Seleccionado",
    "Ui.sign_out": "desconectar",
    "Ui.type_here": "Escriba aquí...",
    "Ui.undo": "Deshacer",
    "UserPreference.anyone": "Cualquiera",
    "UserPreference.anyone_available": "Cualquier persona disponible",
    "UserPreference.assign_me_short_title": "{language}",
    "UserPreference.assign_me_subtitle": "Solo asignarme a alguien que hable {language}",
    "UserPreference.assign_me_title": "Cualquiera que hable {language}",
    "UserPreference.assigned_title": "Su personal asignado",
    "UserPreference.header": "¿Tiene un miembro del personal preferido?",
    "UserPreference.language_title": "{language}",
    "UserPreference.no_preference": "Sin preferencias",
    "UserPreference.random_subtitle": "No tengo preferencia",
    "UserPreference.random_title": "Cualquier persona disponible",
    "UserPreference.see_all_staff": "Ver todo el personal",
    "UserPreference.specific_subtitle": "Sé exactamente con quién quiero reunirme",
    "UserPreference.specific_title": "Un miembro del personal específico",
    "Validator.address": "dirección",
    "Validator.cell_phone": "número de teléfono móvil",
    "Validator.city": "ciudad",
    "Validator.country": "país",
    "Validator.email": "correo electrónico",
    "Validator.first_name": "nombre de pila",
    "Validator.home_phone": "teléfono de casa",
    "Validator.last_name": "apellido",
    "Validator.meeting_method": "método de reunión",
    "Validator.notes": "notas",
    "Validator.phone_code": "Código de país incorrecto",
    "Validator.phone_country_code": "Proporcione un código de país de 1 a 3 dígitos precedido por '+' y seguido de su número de teléfono.",
    "Validator.phone_length": "Longitud de teléfono incorrecta",
    "Validator.phone_valid": "Por favor provea un número de teléfono válido.",
    "Validator.postal_code": "código postal",
    "Validator.province": "provincia",
    "Validator.state": "estado",
    "Validator.work_phone": "teléfono del trabajo",
    "Validator.zip_code": "código postal",
    "ViewOnMapButton.title": "Ver en el mapa",
    "WaitTime.loading": "Obteniendo tiempo de espera ...",
    "WaitTime.no_walkins_currently": "Actualmente no se aceptan visitas sin cita previa",
    "WaitTime.wait": "Tiempo de espera actual sin cita: {wait}",
    "WaitTime.wait_time": "Tiempo de espera actual sin cita previa: {waitTime}",
    "WeeklyDatePicker.next_week": "La próxima semana",
    "WeeklyDatePicker.previous_week": "Semana pasada",
    "WhatNow.homepage": "Vaya al sitio web {vendor}",
    "WhatNow.title": "¿Ahora que?"
}