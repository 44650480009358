import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useHistory, useLocation } from 'react-router-dom';
import mode from '../../shared/helpers/Mode';
import { HEADER_HEIGHT, SHORTCUTS, PAGES } from '../constants';
import {
  AppointmentContext,
  EMPTY_APPOINTMENT,
} from '../contexts/AppointmentContext';
import { FeatureContext } from '../contexts/FeatureContext';
import { HeaderContext } from '../contexts/HeaderContext';
import { LocaleContext } from '../contexts/LocaleContext';
import {
  SelectionContext,
  EMPTY_SELECTION,
} from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import Open from '../helpers/api/Open';
import Item from '../helpers/Item';
import Resources from '../helpers/Resources';
import Tracker from '../helpers/Tracker';
import Url from '../helpers/Url';
import DesktopAppointment from './desktop/ManageAppointment';
import MobileAppointment from './mobile/ManageAppointment';

const useStyles = createUseStyles({
  desktop: {
    flexGrow: 1,
  },
  desktopContainer: {
    minHeight: `calc(100vh - ${HEADER_HEIGHT.DESKTOP})`,
    display: 'flex',
  },
});

const ManageAppointment = () => {
  const intl = useIntl();
  const classes = useStyles();

  const Api = Open.api();

  const history = useHistory();
  const location = useLocation();
  const viewMode = useContext(ViewModeContext);
  const { firstStep } = useContext(SettingsContext);
  const [, setHeader] = useContext(HeaderContext);
  const [locale, setLocale] = useContext(LocaleContext);
  const { locationLocking } = useContext(FeatureContext);
  const [timezone, setTimezone] = useContext(TimezoneContext);
  const [selections, setSelections] = useContext(SelectionContext);
  const [appointment, setAppointment] = useContext(AppointmentContext);

  const [loading, setLoading] = useState(false);

  const confirmation = location.pathname.includes('/confirmation');
  const vendorName = window.state.vendor_name;

  useEffect(() => {
    const { lang } = Url.params(window.location.search);

    if (lang) {
      setLocale(lang);
    }

    document.title = intl.formatMessage({ id: 'Manage.confirmation' });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      history.listen(() => {
        if (
          confirmation &&
          (history.action === 'POP' || history.action === 'REPLACE')
        ) {
          setAppointment({ ...EMPTY_APPOINTMENT, reloading: true });
          setSelections(EMPTY_SELECTION);

          const params = Url.params(selections.search);

          if (appointment.location && locationLocking) {
            params.location = appointment.location;
          }

          const query = Object.keys(params)
            .filter((key) => key !== SHORTCUTS.QUEUE)
            .map((key) => `${key}=${params[key]}`)
            .join('&');

          window.location.href = query ? `/?${query}` : '/';
        }
      }),

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, setSelections],
  );

  useEffect(() => {
    const {
      confirm_code: code,
      cid: clientId,
      partner,
    } = Url.params(location.search);

    const { attendee } = selections;

    const selectionsIncomplete =
      partner ||
      Item.empty(selections) ||
      !appointment.id ||
      !Item.get(attendee, 'id');
    const shouldFetchAppointment = Boolean(!confirmation && code && clientId);

    if (selectionsIncomplete && shouldFetchAppointment) {
      setLoading(true);

      Api.locale(locale)
        .appointments()
        .matching({ code, id: clientId })
        .get()
        .then(({ data: { data, included } }) => {
          if (data.length) {
            const {
              id,
              attributes: {
                client_end: end,
                dial_in: dialIn,
                meeting_link: meetingLink,
                meeting_method: meetingMethod,
                reschedulable,
                start,
                status,
                booking_shortcut: bookingShortcut,
                book_another_link: bookAnotherLink,
              },
              relationships: {
                location: requestLocation,
                user: requestUser,
                additionalUsers: { data: addUsers },
              },
            } = Item.first(data);

            const userId = requestUser?.data?.id;

            const endRaw = end;
            const startRaw = start;
            const users = Item.included(included, 'users', []);
            const loc = Item.first(Item.included(included, 'locations'));
            const service = Item.first(Item.included(included, 'services'));
            const user = users.find((user) => user.id === userId);
            const attendee = Item.first(Item.included(included, 'attendees'));
            const additionalUserIds = addUsers
              ? addUsers.map((addUser) => addUser.id)
              : [];
            const additionalUsers = users.filter((addUser) =>
              additionalUserIds.includes(addUser.id),
            );

            if (attendee.attributes.timezone) {
              setTimezone(attendee.attributes.timezone);
            }

            const newSelections = {
              additionalUsers: additionalUsers
                ? additionalUsers.map((addUser) =>
                    Resources.formatUser(addUser),
                  )
                : [],
              appointment: id,
              meetingMethod,
              location: Resources.formatLocation(loc),
              service: Resources.formatService(service),
              settings: bookingShortcut?.settings,
              shortcuts: {
                booking_shortcut_id: bookingShortcut?.id,
                location: bookingShortcut?.location_id,
                location_category_id: bookingShortcut?.location_category_id,
                meeting_method: bookingShortcut?.meeting_method,
                service: bookingShortcut?.service_id,
                settings: bookingShortcut?.settings,
              },
              user: user ? Resources.formatUser(user) : null,
              date: Resources.formatDate(
                start,
                attendee.attributes.timezone || timezone,
              ),
              attendee: Resources.formatAttendee(attendee),
              confirmCode: attendee.attributes.confirm_code,
            };

            setSelections(newSelections);

            setAppointment({
              id,
              bookingShortcut,
              bookAnotherLink,
              confirmCode: attendee.attributes.confirm_code,
              end,
              endRaw,
              reschedulable,
              startRaw,
              status,
              location: requestLocation.data.id,
              meetingLink,
              dialIn,
            });
            Tracker.view(
              confirmation ? PAGES.CONFIRMATION : PAGES.MANAGE,
              Tracker.getTrackingData(
                confirmation ? PAGES.CONFIRMATION : PAGES.MANAGE,
                firstStep,
                newSelections,
              ),
            );
          } else {
            setAppointment(EMPTY_APPOINTMENT);
          }

          setLoading(false);
        })
        .catch(() => {
          setAppointment(EMPTY_APPOINTMENT);

          setLoading(false);
        });
    } else if (selectionsIncomplete) {
      setAppointment(EMPTY_APPOINTMENT);
    } else {
      Tracker.view(
        confirmation ? PAGES.CONFIRMATION : PAGES.MANAGE,
        Tracker.getTrackingData(
          confirmation ? PAGES.CONFIRMATION : PAGES.MANAGE,
          firstStep,
          {
            ...selections,
            confirmCode: appointment.confirmCode,
          },
        ),
      );
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    setHeader({
      action: null,
      title: vendorName,
    });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, setHeader]);

  const props = {
    confirmation,
    loading,
  };

  mode.handleKioskConfirmation(
    selections,
    () => setSelections(EMPTY_SELECTION),
    true,
    window?.state?.shortcuts?.location?.attributes?.lang,
  );

  return (
    <section className={viewMode !== MOBILE ? classes.desktopContainer : ''}>
      <main className={viewMode !== MOBILE ? classes.desktop : ''}>
        {viewMode === MOBILE ? (
          <MobileAppointment {...props} />
        ) : (
          <DesktopAppointment {...props} />
        )}
      </main>
    </section>
  );
};

export default ManageAppointment;
