import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import CxSnippet from '../../../shared/components/CxSnippet';
import { FeatureDecisionContext } from '../../../shared/contexts/FeatureDecisionContext';
import Dates from '../../../shared/helpers/Dates';
import CenterWrap from '../../components/CenterWrap';
import CircularProgress from '../../components/CircularProgress';
import DateTimeFilter from '../../components/DateTimeFilter';
import FindAvailableDate from '../../components/FindAvailableDate';
import Language from '../../components/icons/Language';
import LobbyBanner from '../../components/LobbyBanner';
import LoginWithGoogle from '../../components/LoginWithGoogle';
import OtherLocationsTimeChunks from '../../components/OtherLocationsTimeChunks';
import TimeChunks from '../../components/TimeChunks';
import TimezonesShownIn from '../../components/TimezonesShownIn';
import Typography from '../../components/Typography';
import WeeklyDatePicker from '../../components/WeeklyDatePicker';
import { FeatureContext } from '../../contexts/FeatureContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { TimezoneContext } from '../../contexts/TimezoneContext';
import { DESKTOP } from '../../contexts/ViewModeContext';
import Open from '../../helpers/api/Open';
import Item from '../../helpers/Item';
import Slots from '../../helpers/Slots';
import Range from '../../prototypes/Range';
import SpacetimeShape from '../../shapes/SpacetimeShape';
import Users from './Users';

const useStyles = createUseStyles((theme) => ({
  picker: {
    borderRight: `1px solid ${theme.palette.neutral[200]}`,
    flexGrow: 1,
    maxWidth: '24rem',
    minWidth: '24rem',
    padding: '2rem',
    width: '24rem',
  },
  availabilities: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'auto',
  },
  availabilityHeader: {
    marginBottom: '1.875rem',
  },
  findNextDateContainer: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    marginLeft: '1.25rem',
    marginRight: '1.25rem',
    paddingBottom: '2rem',
  },
  timeSlots: {
    padding: '2rem',
  },
  loading: {
    padding: '4rem 2rem',
  },
  loadingMessage: {
    marginBottom: '1.5rem',
    marginTop: '1.5rem',
    textAlign: 'center',
  },
  selectedDate: {
    marginTop: '0.5rem',
  },
  time: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '46.875rem',
    // IE11 requires a min-width or it will not be displayed at all
    minWidth: '1rem',
  },
  hidden: {
    display: 'none',
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.75rem 0',
  },
  banner: {
    marginBottom: '0.25rem',
  },
}));

const SpecificUserTime = ({
  earliestDate,
  error,
  initialStartDate,
  loading,
  loadingMessage,
  mode,
  previous,
  previousStep,
  selectDate,
  selected,
  selectTime,
  setInformation,
  showBack,
  slots,
  slotsApiIdRef,
  slotsLoading,
  next,
}) => {
  const Api = Open.api();
  const classes = useStyles({ theme: useTheme() });

  const [locale] = useContext(LocaleContext);
  const features = useContext(FeatureContext);
  const [timezone] = useContext(TimezoneContext);
  const [
    {
      googleUser,
      location,
      locationCategory,
      meetingMethod,
      service,
      settings,
      shortcuts,
      user,
    },
    setSelections,
  ] = useContext(SelectionContext);
  const { shouldUseNextAvailability, shouldUseNextAvailabilityNewView } =
    useContext(FeatureDecisionContext);
  const windowStart = shortcuts?.settings?.booking_window_start;
  const windowEnd = shortcuts?.settings?.booking_window_end;
  const [{ range, skipNextFetchSlots }, setRange] = useReducer(
    (state, newState) => ({
      ...state,
      range: Range.override({
        range: newState.range,
        start: windowStart,
        end: windowEnd,
        endOverride: state.range.endOverride,
        startOverride: state.range.startOverride,
      }),
      skipNextFetchSlots: newState.skipNextFetchSlots,
    }),
    null,
    () => ({
      range: Range.override({
        range: Range.week({ date: selected }),
        start: windowStart,
        end: windowEnd,
        startOverride: windowStart ? Dates.parse(windowStart) : null,
        endOverride: windowEnd ? Dates.parse(windowEnd) : null,
      }),
      skipNextFetchSlots: false,
    }),
  );

  useEffect(() => {
    setRange({ range: Range.week({ date: selected }) });

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  useEffect(() => {
    setInformation({ error: null });
  }, [setInformation, user]);

  const forward = () => {
    if (user) {
      setInformation({ loading: true, error: null });
    }

    setRange({ range: range.next() });
  };
  const backward = () => {
    if (user) {
      setInformation({ loading: true, error: null });
    }

    setRange({ range: range.previous() });
  };

  useEffect(() => {
    if (user) {
      if (skipNextFetchSlots) {
        setRange({ range, skipNextFetchSlots: false });
        return;
      }
      setInformation({ loading: true });

      if (googleUser?.matchAvailability && googleUser?.refreshing) {
        setSelections({ googleUser: { ...googleUser, refreshing: true } });

        return;
      }

      slotsApiIdRef.current += 1;
      const apiId = slotsApiIdRef?.current;
      const startDate = Slots.startDate(range, range.start);
      const endDate = Slots.endDate(range, range.end);

      Api.slots()
        .in(timezone)
        .for(service.id)
        .by(user.id)
        .when(meetingMethod, (api) => api.method(meetingMethod))
        .when(Item.has(location || {}, 'id'), (api) => api.at(location.id))
        .when(!settings.preferred_location && locationCategory?.id, (api) =>
          api.withinLocationCategory(locationCategory.id),
        )
        .between(startDate.format(), endDate.add(1, 'day').format())
        .when(settings?.invite_only_resources, (api) => api.withInviteOnly())
        .when(
          // We are temporarily ignoring the destructuring-assignment rule explicitly.
          // There is a bug that was solved in a newer version of this plugin which
          // we will eventually be able to upgrade to once we can move off of
          // the current version of NodeJS in use.
          //
          // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
          //
          // eslint-disable-next-line react/destructuring-assignment
          features.clientGoogleLogin && googleUser?.matchAvailability,
          (api) => api.google(googleUser.token),
        )
        .get()
        .then(({ data: { data } }) => {
          let slotData = Slots.filterWithinRange(data, startDate, endDate);

          if (service.group) {
            slotData = Slots.filterFilledGroupAppointmentsArray(slotData);
          }
          const slots = Slots.combine(slotData);
          const selected = Slots.getSelectedSlot(slotData, startDate);

          if (slotsApiIdRef?.current === apiId) {
            setInformation({
              error:
                Object.keys(slots).length > 0
                  ? null
                  : error?.messageValues
                  ? error
                  : {
                      messageTitleKey: 'TimeChunks.no_available_times_in_week',
                      messageSubtitleKey: 'TimeChunks.select_another_week',
                    },
              loading: false,
              merge: false,
              selected: selected,
              slots,
            });
          }
        });
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    googleUser?.matchAvailability,
    googleUser?.refreshing,
    meetingMethod,
    location,
    service,
    slotsApiIdRef,
    user,
    setInformation,
    range.end,
    range.start,
    timezone,
  ]);

  const resetRange = () => {
    // when choosing a specific staff, we want to reset the range to the initial start date
    // so the rest of the code can find the earliest date for next availability feature
    if (shouldUseNextAvailability && shouldUseNextAvailabilityNewView) {
      setRange({ range: Range.week({ date: initialStartDate }) });
    }
  };

  const picker = (
    <WeeklyDatePicker
      borders
      chosen={user !== null}
      end={range.end}
      loading={loading}
      onClickDate={selectDate}
      onClickNext={forward}
      onClickPrevious={backward}
      selected={selected}
      slots={slots}
      specific
      start={range.start}
    />
  );

  const findAvailableDate = (
    <FindAvailableDate
      earliestDate={earliestDate}
      loading={loading || slotsLoading || !user}
      range={range}
      selectDate={selectDate}
      selectedDate={selected}
      setInformation={setInformation}
      setRange={setRange}
      slots={slots}
    />
  );

  const filters = (
    <div className={classes.filters}>
      <DateTimeFilter
        content={<TimezonesShownIn mode={DESKTOP} />}
        icon={<Language altText="" ariaHidden />}
      />
      <LoginWithGoogle />
    </div>
  );

  const slotsToDisplay = Item.get(slots, selected.format('iso-short'), []);

  return (
    <CenterWrap custom data-testid="specific-date-time-desktop" view={mode}>
      <Users
        loading={loading}
        previous={previous}
        previousStep={previousStep}
        resetRange={resetRange}
        setInformation={setInformation}
        showBack={showBack}
      />

      <div className={classes.time}>
        <div className={classes.banner}>
          <LobbyBanner next={next} />
        </div>
        {picker}
        {shouldUseNextAvailability && shouldUseNextAvailabilityNewView ? (
          <div className={classes.findNextDateContainer}>
            {findAvailableDate}
          </div>
        ) : null}
        <div
          className={classNames(
            classes.availabilities,
            loading ? classes.loading : classes.timeSlots,
          )}
        >
          {user ? (
            <header className={classes.availabilityHeader}>
              <CxSnippet
                fallback={
                  <Typography component="h3" variant="h6">
                    <FormattedMessage
                      id="DateTime.user_schedule"
                      values={{ name: user.name }}
                    />
                  </Typography>
                }
                targetId="meeting_details_header"
              />

              <Typography
                classes={{ root: classes.selectedDate }}
                component="p"
                variant="caption1"
              >
                {Dates.toDateMonthYear(selected)}
              </Typography>
              {filters}
            </header>
          ) : null}
          {loading ? (
            <div>
              <CircularProgress />
              {loadingMessage ? (
                <div className={classes.loadingMessage}>
                  <Typography component="div" grey variant="regular">
                    <FormattedMessage id={loadingMessage} />
                  </Typography>
                </div>
              ) : null}
            </div>
          ) : (
            <TimeChunks
              error={error}
              group={service.group}
              selected={selected}
              selectTime={selectTime}
              slots={slotsToDisplay}
              specificUser
            />
          )}
          <div className={loading ? classes.hidden : {}}>
            <OtherLocationsTimeChunks
              locationHasSlots={!!slotsToDisplay.length}
              selected={selected}
              selectTime={selectTime}
              specificUser
            />
          </div>
          {shouldUseNextAvailability && !shouldUseNextAvailabilityNewView
            ? findAvailableDate
            : null}
        </div>
      </div>
    </CenterWrap>
  );
};

SpecificUserTime.propTypes = {
  earliestDate: SpacetimeShape,
  initialStartDate: SpacetimeShape,
  loading: PropTypes.bool,
  mode: PropTypes.number.isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  selectDate: PropTypes.func.isRequired,
  selected: SpacetimeShape.isRequired,
  selectTime: PropTypes.func.isRequired,
  setInformation: PropTypes.func.isRequired,
  showBack: PropTypes.bool,
  slots: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.shape({ end: SpacetimeShape, start: SpacetimeShape }),
    ),
  ).isRequired,
  slotsApiIdRef: PropTypes.shape({ current: PropTypes.number }),
  slotsLoading: PropTypes.bool,
  next: PropTypes.func.isRequired,
};

SpecificUserTime.defaultProps = {
  earliestDate: null,
  initialStartDate: Dates.today(),
  loading: true,
  loadingMessage: null,
  previousStep: null,
  showBack: false,
  slotsApiIdRef: {},
  slotsLoading: true,
};

export default SpecificUserTime;
