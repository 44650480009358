import Validator from 'validatorjs';
import en from 'validatorjs/src/lang/en';
import es from 'validatorjs/src/lang/es';
import fr from 'validatorjs/src/lang/fr';
import ko from 'validatorjs/src/lang/ko';
import pl from 'validatorjs/src/lang/pl';
import pt from 'validatorjs/src/lang/pt_BR';
import ru from 'validatorjs/src/lang/ru';
import zh from 'validatorjs/src/lang/zh';
import {
  COUNTRIES_WITH_REGIONS,
  DEFAULT_COUNTRY,
  QUESTION_TYPES,
  REQUIRED_VALUES,
} from '../constants';
import messages from '../locales';

Validator.register(REQUIRED_VALUES.REQUIRED_CHECKBOX, (object) => {
  const keys = Object.keys(object);

  for (let i = 0; i < keys.length; i += 1) {
    if (object[keys[i]] === true) {
      return true;
    }
  }

  return false;
});

Validator.register('phone_country_code', function (value) {
  return value.length === 0 || value[0] === '+';
});

Validator.register('phone_valid', function (value) {
  // Notes:
  // Max length is defined in the e164 standard, min length is not. We inforce a min length of 8 digits.
  // Update the `app/Rules/E164.php` as well should we change this minimum.
  return Boolean(value.match(new RegExp(`^\\+?\\d{8,15}$`, 'g')));
});

Validator.setMessages('en', {
  ...Validator.getMessages('en'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: en.required,
});
Validator.setMessages('fr', {
  ...Validator.getMessages('fr'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: fr.required,
});
Validator.setMessages('es', {
  ...Validator.getMessages('es'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: es.required,
});
Validator.setMessages('ko', {
  ...Validator.getMessages('ko'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: ko.required,
});
Validator.setMessages('pl', {
  ...Validator.getMessages('pl'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: pl.required,
});
Validator.setMessages('pt', {
  ...Validator.getMessages('pt'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: pt.required,
});
Validator.setMessages('ru', {
  ...Validator.getMessages('ru'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: ru.required,
});
Validator.setMessages('zh', {
  ...Validator.getMessages('zh'),
  [REQUIRED_VALUES.REQUIRED_CHECKBOX]: zh.required,
});

export default {
  /**
   * @param {object} values
   * @param {array<object>} questions
   *
   * @returns {object}
   */
  cancel(values, questions) {
    const rules = {};
    const names = {};

    if (questions) {
      questions.forEach((question) => {
        if (question.required) {
          rules[`questions.${question.id}`] = REQUIRED_VALUES.REQUIRED;

          if (values?.questions[question.id] === '0') {
            rules[`additional.${question.id}`] = REQUIRED_VALUES.REQUIRED;
          }
        }

        names[`questions.${question.id}`] = '';
        names[`additional.${question.id}`] = '';
      });
    }

    const validator = new Validator(values, rules);

    validator.setAttributeNames(names);

    if (validator.fails()) {
      return validator.errors.all();
    }

    return {};
  },

  /**
   * @param {object} values
   * @param {object} clientFields
   * @param {array<object>} questions
   *
   * @returns {object}
   */
  details(values, clientFields, questions) {
    const rules = {
      firstName: 'required|max:50',
      lastName: 'required|max:50',
      email: clientFields?.email || 'required|email',
      cellPhone: clientFields.mobile_phone + '|phone_valid|phone_country_code',
      homePhone: clientFields.phone + '|phone_valid|phone_country_code',
      workPhone: clientFields.work_phone + '|phone_valid|phone_country_code',
      address: clientFields.location_information,
      city: clientFields.location_information,
      postalCode: clientFields.location_information,
      country: clientFields.location_information,
      province:
        clientFields.location_information === REQUIRED_VALUES.REQUIRED &&
        !COUNTRIES_WITH_REGIONS.includes(values.country)
          ? 'present'
          : clientFields.location_information,
      notes: clientFields.notes,
    };

    const names = {
      firstName: messages[this.lang || 'en']['Validator.first_name'],
      lastName: messages[this.lang || 'en']['Validator.last_name'],
      email: messages[this.lang || 'en']['Validator.email'],
      cellPhone: messages[this.lang || 'en']['Validator.cell_phone'],
      homePhone: messages[this.lang || 'en']['Validator.home_phone'],
      workPhone: messages[this.lang || 'en']['Validator.work_phone'],
      address: messages[this.lang || 'en']['Validator.address'],
      city: messages[this.lang || 'en']['Validator.city'],
      postalCode:
        messages[this.lang || 'en'][
          values.country === DEFAULT_COUNTRY
            ? 'Validator.zip_code'
            : 'Validator.postal_code'
        ],
      country: messages[this.lang || 'en']['Validator.country'],
      province:
        messages[this.lang || 'en'][
          values.country === DEFAULT_COUNTRY
            ? 'Validator.state'
            : 'Validator.province'
        ],
      notes: messages[this.lang || 'en']['Validator.notes'],
    };

    questions.forEach((question) => {
      if (question.required) {
        rules[`questions.${question.id}`] = REQUIRED_VALUES.REQUIRED;

        if (question.type === QUESTION_TYPES.CHECKBOX) {
          rules[`questions.${question.id}`] = REQUIRED_VALUES.REQUIRED_CHECKBOX;
        }
      }

      names[`questions.${question.id}`] = '';
    });

    // filter out empty rules, since Validator cannot handle them
    Object.keys(rules).forEach((name) => {
      if (rules[name] === undefined) {
        delete rules[name];
      }
    });

    const error_messages = {
      phone_country_code:
        messages[this.lang || 'en']['Validator.phone_country_code'],
      phone_valid: messages[this.lang || 'en']['Validator.phone_valid'],
    };

    const validator = new Validator(values, rules, error_messages);

    validator.setAttributeNames(names);

    if (validator.fails()) {
      return validator.errors.all();
    }

    return {};
  },

  /**
   * Validate that the new attendee is not a duplicate of the main attendee
   * or any of the additional attendees.
   *
   * @param {object} values
   * @param {object} attendee
   * @param {array<object>} attendees
   * @param {boolean} additional
   * @param {number} index
   *
   * @returns {object}
   */
  duplicate(values, attendee, attendees, additional = false, index = -1) {
    if (!attendees.length) {
      return {};
    }

    let duplicate = attendees.find(
      (item, key) =>
        key !== index &&
        item.firstName === values.firstName &&
        item.lastName === values.lastName &&
        item.email.toLowerCase() === values.email.toLowerCase(),
    );

    if (additional && attendee) {
      const matchesMain =
        attendee.firstName === values.firstName &&
        attendee.lastName === values.lastName &&
        attendee.email.toLowerCase() === values.email.toLowerCase();

      duplicate = duplicate || matchesMain;
    }

    if (duplicate) {
      return { duplicate: true };
    }

    return {};
  },

  /**
   * @param {string} locale
   *
   * @returns {undefined}
   */
  locale(locale) {
    this.lang = locale;

    // We are explicilty ignoring the react-hooks/rules-of-hooks as this is
    // a false positive based on the naming of the function being called.
    //
    // eslint-disable-next-line react-hooks/rules-of-hooks
    Validator.useLang(locale);
  },

  meetingMethod(values) {
    const rules = {
      meetingMethod: 'required',
    };

    const names = {
      meetingMethod: messages[this.lang || 'en']['Validator.meeting_method'],
    };

    const validator = new Validator(values, rules);

    validator.setAttributeNames(names);

    if (validator.fails()) {
      return validator.errors.all();
    }

    return {};
  },
};
