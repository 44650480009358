import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Detective from '../../shared/helpers/Detective';
import { PAGES } from '../constants';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import Tracker from '../helpers/Tracker';
import { Anchor } from './Anchor';
import Modal from './Modal';
import TrackPageView from './TrackPageView';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '14rem',
  },
  header: {
    paddingTop: '1rem',
    textAlign: 'center',
  },
  item: {
    paddingBottom: '.25rem',
    '&:last-of-type': {
      paddingBottom: 0,
    },
  },
  externalBtn: {
    textDecoration: 'none',
  },
  message: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.sm,
    textAlign: 'center',
  },
}));

const FastRouteModal = ({
  handleDismiss,
  open,
  handleServiceSelect,
  data: { message, url },
}) => {
  const classes = useStyles({ theme: useTheme() });
  const { firstStep } = useContext(SettingsContext);
  const [selections] = useContext(SelectionContext);

  const handleSkipTheWaitClick = () => {
    Tracker.view(
      PAGES.FASTER_ROUTE_LINK,
      Tracker.getTrackingData(PAGES.FASTER_ROUTE_LINK, firstStep, selections),
    );
    window.open(url, '_parent', 'noopener=false, noreferrer=false');
  };

  return (
    <Modal
      header={
        <Typography
          classes={{ root: classes.header }}
          component="h2"
          id="FastRouteTitle"
          variant="h6"
        >
          <FormattedMessage id="FastRoute.title" />
        </Typography>
      }
      isOpen={open}
      label="fast-route"
      onRequestClose={handleDismiss}
      testId="fast-route"
    >
      <>
        <TrackPageView identifier={PAGES.FASTER_ROUTE} />
        <div className={classes.container}>
          {message ? (
            <div className={classes.item}>
              <Typography className={classes.message} variant="body2">
                <div dangerouslySetInnerHTML={{ __html: message }} />
              </Typography>
            </div>
          ) : null}

          {url ? (
            <div className={classes.item}>
              <Anchor
                data-testid="fast-route-skip-wait"
                href={url}
                onClick={handleSkipTheWaitClick}
                type="button"
                variant="title"
              >
                <FormattedMessage id="FastRoute.skip_the_wait" />
              </Anchor>
            </div>
          ) : null}

          <div className={classes.item}>
            <Anchor
              data-testid="fast-route-book-anyway"
              // We should try to avoid empty href, as screen readers lose context of where
              // the links go. Anchors must ALWAYS have an href, though, or they aren't able
              // to be accessed via keyboard. So this is 'okay' but not 'great'.
              href="#"
              id="fast-route-book-anyway-link"
              onClick={handleServiceSelect}
              preventDefault={Detective.inIFrame()}
              type="default"
              variant="body1"
            >
              <FormattedMessage id="FastRoute.book_anyway" />
            </Anchor>
          </div>
        </div>
      </>
    </Modal>
  );
};

FastRouteModal.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
  open: PropTypes.bool,
  data: PropTypes.shape({
    message: PropTypes.string,
    url: PropTypes.string,
  }),
};

FastRouteModal.defaultProps = {
  data: PropTypes.shape({
    message: null,
    url: null,
  }),
  open: false,
};

export default FastRouteModal;
