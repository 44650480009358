import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import BookingValidationErrorModal from '../../components/BookingValidationErrorModal';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Typography from '../../components/Typography';
import { MOBILE } from '../../contexts/ViewModeContext';
import Times from '../../steps/mobile/Times';

const useStyles = createUseStyles({
  root: {
    overflowY: 'auto',
  },
  buttonSpacing: {
    marginTop: '0.5rem',
  },
});

const Reschedule = ({
  confirmContent,
  errorOpen,
  errorMessage,
  exclusion,
  handleClear,
  handleClose,
  handleConfirm,
  confirmOpen,
  rescheduleLoading,
  step,
  ...props
}) => {
  const classes = useStyles();

  const subtitle = (
    <Typography component="p" variant="body1">
      <FormattedMessage id="Reschedule.reschedule" />
    </Typography>
  );

  const header = (
    <Typography component="h2" id="RescheduleModal" variant="h6">
      <FormattedMessage id="Reschedule.confirm_time" />
    </Typography>
  );

  const buttons = (
    <>
      <Button
        classes={{ override: classes.buttonSpacing }}
        fullWidth={false}
        onClick={handleClear}
        type="button"
        variant="secondary"
      >
        <FormattedMessage id="Ui.clear" />
      </Button>
      <Button
        fullWidth={false}
        isLoading={rescheduleLoading}
        onClick={handleConfirm}
        type="button"
      >
        <FormattedMessage id="Ui.confirm" />
      </Button>
    </>
  );

  return (
    <section className={classes.root} data-testid="mobile-reschedule">
      <Times
        exclusion={exclusion}
        mode={MOBILE}
        rescheduling
        subtitle={subtitle}
        {...props}
      />
      <Modal
        buttons={buttons}
        header={header}
        isOpen={confirmOpen}
        label={<FormattedMessage id="Reschedule.label" />}
        onRequestClose={handleClear}
      >
        {confirmContent}
      </Modal>
      <BookingValidationErrorModal
        errorMessage={errorMessage}
        handleClose={handleClose}
        open={errorOpen}
        step={step}
      />
    </section>
  );
};

Reschedule.propTypes = {
  confirmContent: PropTypes.node.isRequired,
  confirmOpen: PropTypes.bool.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorOpen: PropTypes.bool.isRequired,
  exclusion: PropTypes.string,
  handleClear: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  rescheduleLoading: PropTypes.bool.isRequired,
  step: PropTypes.shape({
    title: PropTypes.string.isRequired,
    action: PropTypes.func.isRequired,
  }),
};

Reschedule.defaultProps = {
  errorMessage: null,
  exclusion: null,
};

export default Reschedule;
