import { Button, IconButton, Typography } from '@coconut-software/ui';
import { Close } from '@coconut-software/ui/dist/icons';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import { default as LegacyButton } from './Button';
import CookieNoticeLearnMore from './CookieNoticeLearnMore';

const useStyles = createUseStyles((theme) => ({
  dismiss: {
    background: theme.palette.neutral[500],
    '&:focus': {
      boxShadow: theme.shadows.dismiss,
    },
    '&:hover': {
      background: theme.palette.neutral[600],
    },
    '&:active': {
      background: theme.palette.neutral[700],
    },
  },
}));

const CookieNotice = ({ setCookie, setDismissed }) => {
  const intl = useIntl();
  const mode = useContext(ViewModeContext);
  const classes = useStyles({ theme: useTheme() });
  const focusRef = useRef(null);

  const handleAccept = () => {
    setCookie();
  };

  const handleDismiss = () => {
    setDismissed(true);
  };

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.focus();
    }
  }, []);

  return (
    <section
      aria-label={intl.formatMessage({ id: 'CookieNotice.title' })}
      className={`fixed bottom-0 z-10 flex items-center justify-between w-full p-5 py-3 bg-black-90 ${
        mode === MOBILE ? 'flex-col' : ''
      }`}
      ref={focusRef}
      tabIndex={-1}
    >
      <div className={`${mode === MOBILE ? 'text-center px-4' : 'pl-16'}`}>
        <span className="mr-4">
          <Typography color="inverse" variant="body1">
            <FormattedMessage id="CookieNotice.message" />
          </Typography>
        </span>
        <span className="inline-block">
          <CookieNoticeLearnMore />
        </span>
      </div>
      {mode === MOBILE ? (
        <div className="w-full">
          <div className="pt-5 pb-2">
            <LegacyButton onClick={handleAccept}>
              <FormattedMessage id="CookieNotice.accept" />
            </LegacyButton>
          </div>
          <LegacyButton
            classes={{ override: classes.dismiss }}
            onClick={handleDismiss}
          >
            <FormattedMessage id="CookieNotice.ignore" />
          </LegacyButton>
        </div>
      ) : (
        <div className="flex items-center pr-16">
          <div className="ml-5">
            <Button color="inverse" onClick={handleAccept} variant="outlined">
              <span className="uppercase whitespace-no-wrap">
                <FormattedMessage id="CookieNotice.accept" />
              </span>
            </Button>
          </div>
          <IconButton
            aria-label={intl.formatMessage({ id: 'CookieNotice.ignore' })}
            color="inverse"
            onClick={handleDismiss}
            size="medium"
          >
            <Close />
          </IconButton>
        </div>
      )}
    </section>
  );
};

CookieNotice.propTypes = {
  setCookie: PropTypes.func.isRequired,
  setDismissed: PropTypes.func.isRequired,
};

export default CookieNotice;
