import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { withTheme } from 'react-jss';
import ReactModal from 'react-modal';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import ModalContent from './ModalContent';

const widths = {
  large: '40rem',
  default: '30rem',
};

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#app');
}

const Modal = ({
  buttons,
  children,
  header,
  isOpen,
  label,
  modalContent,
  onRequestClose,
  styles,
  testId,
  theme,
  width,
}) => {
  const mode = useContext(ViewModeContext);

  const customStyles = {
    content: {
      border: 'none',
      borderRadius: '2px',
      bottom: 'auto',
      boxShadow: '0 19px 38px rgba(0,0,0,.5)',
      display: 'flex',
      fontFamily: theme.fontFamilies.sansSerif,
      left: '1.25rem',
      maxHeight: 'calc(100% - 6rem)',
      minHeight: '1px',
      overflow: 'hidden',
      padding: 0,
      right: '1.25rem',
      top: 'auto',
    },
    overlay: {
      background: 'rgba(50, 50, 50, 0.5)',
      zIndex: 1000000,
    },
  };

  const mobileContent = {
    bottom: '1.25rem',
    left: '1.25rem',
    right: '1.25rem',
    top: 'auto',
  };

  const desktopContent = {
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: widths[width],
  };

  return (
    <ReactModal
      aria={{ labelledby: label }}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          ...customStyles.content,
          ...(mode === MOBILE ? mobileContent : desktopContent),
          ...styles.content,
        },
        overlay: customStyles.overlay,
      }}
    >
      {modalContent || (
        <ModalContent buttons={buttons} header={header} testId={testId}>
          {children}
        </ModalContent>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  buttons: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  header: PropTypes.element,
  isOpen: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  modalContent: PropTypes.element,
  onRequestClose: PropTypes.func,
  styles: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ),
  theme: PropTypes.shape({
    fontFamilies: PropTypes.shape({ sansSerif: PropTypes.string }),
  }).isRequired,
  testId: PropTypes.string,
  width: PropTypes.oneOf(['large', 'default']),
};

Modal.defaultProps = {
  buttons: null,
  children: null,
  header: null,
  isOpen: false,
  modalContent: null,
  onRequestClose: () => {},
  styles: {},
  testId: null,
  width: 'default',
};

export default withTheme(Modal);
