import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import Forms from '../helpers/Forms';
import QuestionShape from '../shapes/QuestionShape';
import AdditionalDetailsForm from './forms/desktop/AdditionalDetailsForm';
import Modal from './Modal';

const AddAttendeeModal = ({
  addAdditionalAttendee,
  countries,
  country,
  open,
  questions,
  handleClose,
  selectedAttendee,
  setSelectedAttendee,
  setUploads,
  validate,
}) => {
  const handleSubmit = (values) => addAdditionalAttendee(values);

  const form = (attendee) => (
    <Formik
      initialValues={Forms.attendeeInitialValues(attendee, questions)}
      onSubmit={handleSubmit}
      render={(props) => (
        <AdditionalDetailsForm
          {...props}
          countries={countries}
          country={country}
          questions={questions}
          selectedAttendee={selectedAttendee}
          setSelectedAttendee={setSelectedAttendee}
          setUploads={setUploads}
        />
      )}
      validate={validate}
      validateOnChange={false}
    />
  );

  return (
    <Modal
      isOpen={open}
      label="AddAttendeeModal"
      modalContent={selectedAttendee ? form(selectedAttendee) : null}
      onRequestClose={handleClose}
      width="large"
    />
  );
};

AddAttendeeModal.propTypes = {
  addAdditionalAttendee: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  country: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  questions: PropTypes.arrayOf(QuestionShape),
  selectedAttendee: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      ),
      PropTypes.number,
      PropTypes.string,
    ]),
  ),
  setSelectedAttendee: PropTypes.func.isRequired,
  setUploads: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

AddAttendeeModal.defaultProps = {
  handleClose: () => {},
  questions: null,
  selectedAttendee: null,
};

export default AddAttendeeModal;
