import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import List from '../../components/List';
import LoadingState from '../../components/LoadingState';
import LocationHeader from '../../components/LocationHeader';
import MobileCard from '../../components/mobile/Card';
import StepCounter from '../../components/StepCounter';
import TrackPageView from '../../components/TrackPageView';
import Typography from '../../components/Typography';
import { PAGES } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import Step from '../../helpers/Step';
import SupportedLocalePreferenceShape from '../../shapes/SupportedLocalePreferenceShape';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
    minHeight: '100%',
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
}));

const UserPreference = ({
  canStepBackwards,
  currentStep,
  preferences,
  previous,
  previousStep,
  stepsCount,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const [, setHeader] = useContext(HeaderContext);
  const { firstStep } = useContext(SettingsContext);
  const [{ location, skip }, setSelections] = useContext(SelectionContext);

  useEffect(() => {
    const canGoToPrevStep = Step.canGoToPrevStep({
      step: 'staff',
      previousStep,
      firstStep,
      skip,
      canStepBackwards,
    });

    setHeader({
      action: canGoToPrevStep ? previous : null,
      title: <FormattedMessage id="Steps.staff" />,
      previousStep,
    });
  }, [canStepBackwards, firstStep, previous, previousStep, setHeader, skip]);

  const setPreference = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    setSelections({ userPreference: { id } });
  };

  const header = (
    <header aria-atomic="false" className={classes.header} role="alert">
      {location ? <LocationHeader location={location} /> : null}
      <StepCounter currentStep={currentStep} stepsCount={stepsCount} />
      <Typography component="h1" variant="h5">
        <FormattedMessage id="UserPreference.header" />
      </Typography>
    </header>
  );

  return (
    <section className={classes.root} data-testid="user-preference-mobile">
      <TrackPageView identifier={PAGES.USER_PREFERENCE} />
      {header}
      <List>
        {preferences.length > 0 ? (
          preferences.map((preference) => (
            <MobileCard
              action={setPreference}
              data-testid={preference.testId}
              icon
              id={preference.id}
              key={preference.id}
              primary={
                <FormattedMessage
                  id={preference.title}
                  values={preference.values || {}}
                />
              }
              secondary={
                <FormattedMessage
                  id={preference.subtitle}
                  values={preference.values || {}}
                />
              }
            />
          ))
        ) : (
          <LoadingState />
        )}
      </List>
    </section>
  );
};

UserPreference.propTypes = {
  canStepBackwards: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  preferences: PropTypes.arrayOf(SupportedLocalePreferenceShape).isRequired,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  stepsCount: PropTypes.number.isRequired,
};

UserPreference.defaultProps = {
  previousStep: null,
};

export default UserPreference;
